.delivery {
  background-color: #B30943;
  min-height: 100vh;
  color: white;
}

/** delivery Headers */

.delivery-header {
  background-color: #5B001E;
  height: 80px;
  font-size: 2em;
  position: fixed;
  width: 100%;
  z-index: 10;
}

.delivery-header-text {
  position: relative;
  top: 15px;
  padding-left: 30px;
}

.delivery-header i {
  position: relative;
  top: 15px;
  padding: 0 10px;
}

.delivery-header-bottom {
  background-color: #6F0429;
  width: 100%;
  height: 70px;
  padding: 10px 0 10px 0;
}

.delivery-header-bottom>span {
  position: relative;
  opacity: 0.8;
  padding-right: 20px;
  top: 3px;
}

.delivery-lower-part {
  position: relative;
  top: 80px;
  padding-bottom: 100px;
}

/** delivery Headers */

.overview-box {
  background-color: #BC4D4F;
  margin-bottom: 10px;
  border-radius: 3px 3px 3px 3px;
  text-align: center;
  padding: 10px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.22);
}

.overview-box-middle {
  margin-left: -10px;
  margin-right: -10px;
  background-color: #974542;
  color: #FFF;
  margin-top: 35px;
  padding: 15px 0 15px 0;
}

.overview-box-bottom {
  margin-left: -10px;
  margin-right: -10px;
  background-color: #fff;
  color: #000;
  padding: 15px 0 15px 0;
  border-radius: 0 0 3px 3px;
  margin-bottom: -10px;
}

.tabular-info {
  font-size: 12px;
}

.droid-module.delivery .chart-wrapper,
.droid-module.delivery .overview-box {
  background: #BC4D4F;
}

.droid-module.delivery .chart-wrapper .month-day-filter {
  display: none;
}

.droid-module.delivery .overview-box-middle {
  background: #5B001E;
}

.chart-wrapper-progress-circle-delivery {
  background: none;
  min-height: auto;
}

.chart-wrapper-progress-circle-delivery .CircularProgressbar .CircularProgressbar-trail {
  stroke: #974542;
}

.chart-wrapper-progress-circle-delivery .CircularProgressbar .CircularProgressbar-path {
  stroke: #fff;
}

.chart-wrapper-progress-circle-delivery .CircularProgressbar .CircularProgressbar-text {
  fill: #fff;
}

.chart-wrapper-progress-circle-delivery .progress-circle {
  padding: 0px;
}

@media (max-width: 767.98px) {
  .delivery-header {
    font-size: 24px;
  }
  .delivery-header div {
    top: 6px;
  }
}