.droid-module.connector {
  height: 100vh;
  background: radial-gradient(#385B98, #111D2F);
  background: #243E5C;
  color: white;
}

.item-conector {
  overflow: hidden;
  width: 100%;
  color: white;
  display: flex;
  justify-content: center;
  font-size: 3em;
  line-height: 2em;
  z-index: 10;
}

.item-connector a {
  text-decoration: none;
}

.item-connector i {
  color: #16FFFF;
  font-size: 70px;
  background: #243E5C;
}

.item-connector i::before,
.item-conector i::after {
  background: #243E5C;
}

.item-connector span.uppercase.bold {
  display: table;
  background: #243E5C;
  top: -25px;
}

.connector-line {
  stroke-width: 3px;
  stroke: rgba(22, 255, 255, 1);
}

.connector-lines {
  position: relative;
  left: -74%;
  top: 0px;
  z-index: 0;
}

.item-connector.inactive i {
  color: rgb(22, 125, 136);
}

.item-connector.inactive a {
  cursor: unset;
}

.item-connector.inactive span.uppercase.bold {
  opacity: 0.7;
}

.connector-node #connector-icon-enrichment {
  text-align: center;
}

.connector-icon-center {
  z-index: 10;
}

.droid-module.connector .item-center {
  top: 45%;
  left: 42.5%;
}

.item-website {
  left: 8%;
  top: 27%;
}

.item-geo {
  left: 22%;
  top: 13%;
}

.item-mobile {
  left: 44%;
  top: 12%;
}

.item-messages {
  left: 67%;
  top: 13%;
}

.item-newsletter {
  left: 80%;
  top: 27%;
}

.item-retargeting {
  left: 84%;
  top: 45%;
  z-index: 30 !important;
}

.item-products {
  left: 75%;
  top: 61%;
  z-index: 40 !important;
}

.item-social {
  left: 55%;
  top: 70%;
  z-index: 40 !important;
}

.item-enrichment {
  left: 30%;
  top: 70%;
  z-index: 40 !important;
}

.item-order {
  left: 10%;
  top: 63%;
  z-index: 30 !important;
}

.item-crm {
  left: 5%;
  top: 45%;
}

/** Connector Timeline **/

.connector-timeline {
  border-right: 1px solid #16FFFF;
  height: 100vh;
}

.connector-timeline-header {
  text-align: center;
  padding: 40px 0;
}

.connector-timeline-header svg {
  position: relative;
  height: unset;
  width: unset;
}

.connector-timeline-header i {
  float: right;
  padding-right: 30px;
  cursor: pointer;
}

.single-timeline {
  color: #16FFFF;
  font-size: 0.8em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 5px;
}

.single-timeline span::after {
  content: ' | ';
}

.single-timeline span:last-of-type::after {
  content: '';
}

.single-timeline span:last-of-type::after,
.single-timeline span.url::after {
  content: '';
}

.add-interface-wrapper {
  height: 460px;
  width: 80vw;
  max-width: 1000px;
  background: white;
  border-radius: 3px;
  text-align: center;
  position: relative;
}

.add-interface-wrapper button {
  margin: 0px 40px;
  background: #243E5C;
  color: white;
  border: none;
  border-radius: 3px;
  padding: 5px;
  margin-bottom: 15px;
}

.add-interface-wrapper .add-interface-button-close {
  background: darkgray;
  color: black;
}

.add-interface-header {
  background: #16FFFF;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  padding: 20px 0;
}

.add-interface-body-channel,
.add-interface-body-arrow {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.add-interface-body i {
  font-size: 6em;
  font-weight: 600;
}

.add-interface-body hr {
  border: 1px solid black;
  width: 95%;
  margin: 10px auto;
}

.api-credentials-input {
  margin: 20px 0;
}

.add-interface-body label {
  float: right;
}

.add-interface-body input {
  float: left;
}

.add-interface-img-wrapper {
  width: 200px;
  height: 200px;
  margin: 0 auto;
}

.add-interface-img {
  max-width: 200px;
  max-height: 200px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#interface-select {
  position: relative;
  display: block;
  margin: 0 auto;
  width: 80%;
}

.add-interface-footer {
  position: absolute;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

@media (max-width: 767.98px) {
  .inner-connector-spider {
    display: none;
  }

  .connector-timeline {
    border: none;
  }
}

@media (max-height: 640px) {
  .connector-timeline-header i {
    display: none;
  }
}