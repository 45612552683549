.data {
  background-color: #1853b3;
  min-height: 100vh;
  color: white;
}

.data-notify {
  background: #1F75D5;
  color: white;
}

.data-notify-progressbar {
  background: white;
}

/** Data Headers */

.data-header {
  background-color: #1F75D5;
  height: 80px;
  font-size: 2em;
  position: fixed;
  width: 100%;
  z-index: 10;
}

.data-header-text {
  position: relative;
  top: 15px;
  padding-left: 30px;
}

.data-header i {
  position: relative;
  top: 15px;
  padding: 0 10px;
}

.data-header a {
  color: white;
}

.data-header-bottom {
  background-color: rgb(25, 93, 186);
  width: 100%;
  height: 70px;
  padding: 10px 0 10px 0;
}

.data-header i {
  cursor: pointer;
}

.data-header-bottom .time-span-text {
  position: relative;
  opacity: 0.8;
  padding-right: 20px;
  top: 3px;
}

.data-header-bottom .show-charts-mobile {
  display: none;
}

.data-header-bottom .data-swiper-wrapper {
  max-width: 200px;
  float: left;
  padding-top: 15px;
  margin-left: 10px;
}

.data-header-bottom .data-swiper-wrapper .single-slide {
  text-align: center;
}

.data-lower-part {
  position: relative;
  top: 80px;
  padding-bottom: 100px;
}

/** DateRangePicker **/

.data-header-bottom .DateRangePicker {
  margin-right: 10px;
}

.data-header-bottom .DateRangePicker .DateRangePickerInput,
.data-header-bottom .DateRangePicker .DateInput,
.data-header-bottom .DateRangePicker .DateInput_input {
  /* background: #0587D6; */
  background: transparent;
  color: white;
  border: 1px solid transparent;
  border-radius: 5px;
  font-weight: 400;
  cursor: pointer;
}

.data-header-bottom svg:not(.DayPickerNavigation_svg__horizontal) {
  fill: white;
}

.data-header-bottom #data-bottom-header-startdate,
.data-header-bottom #data-bottom-header-startdate::placeholder,
.data-header-bottom #data-bottom-header-enddate,
.data-header-bottom #data-bottom-header-enddate::placeholder {
  color: white;
}

.data-header-bottom .DateInput_input__focused {
  border-bottom: 2px solid #28A0FC !important;
  border-radius: 0 !important;
}

.CalendarDay__selected_span {
  background: #82e0aa;
  color: white;
  border: 1px solid white;
}

.CalendarDay__selected {
  background: rgb(25, 93, 186);
  color: white;
}

.CalendarDay__selected:hover {
  background: #1F75D5;
  color: white;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: #28A0FC;
  color: white;
}

.data-header-bottom .CalendarDay__selected_span {
  background: #28A0FC;
}

.timespan-datepicker {
  width: 700px;
  float: right;
}

.timespan-datepicker .data-timespan-select__control,
.timespan-datepicker .data-timespan-select__control--is-focused {
  float: left;
  width: 200px;
  top: 8px;
  background: #1F75D5;
  color: white;
  border: none !important;
  color: white !important;
  box-shadow: none;
  border-radius: 3px;
}

.data-timespan-select__option--is-selected {
  background: #28A0FC !important;
}

.data-timespan-select__option--is-focused {
  background: white !important;
  color: black !important;
}

.data-timespan-select__placeholder,
.data-timespan-select__single-value {
  color: white !important;
}

.data-timespan-select__indicators {
  display: block !important;
}

.data-timespan-select__menu {
  color: white !important;
  text-align: left;
  max-width: 250px;
  background: #1F75D5 !important;
}

/** Charts **/

.data-charts-single {
  padding: 10px;
}

.data-charts-single .react-card-flip {
  width: 100%;
  height: 440px;
}

.chart-wrapper {
  position: relative;
  background-color: #28A0FC;
  height: inherit;
  min-height: 290px;
  border-radius: 3px;
  color: white;
}

.chart-wrapper canvas {
  max-height: 390px;
}

.month-day-filter {
  position: relative;
  top: 0;
  font-size: 0.9em;
}

.droid-data-monthday {
  margin: 0 15px;
  padding: 0 5px;
  cursor: pointer;
}

.droid-data-monthday.active {
  border-bottom: 1px solid #15FFFB;
}

.delivery-background .chart-wrapper {
  background-color: #BC4D4F;
}

.chart-wrapper.line {
  height: 430px;
}

.chart-wrapper.pie {
  border-radius: 0;
}

.chart-wrapper-progress-bar {
  border-radius: 0;
}

.chart-wrapper-progress-bar.hidden {
  display: none;
}

#data-line-chart {
  position: relative;
}

.chart-wrapper-top {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-color: #28A0FC;
  padding: 15px 0;
  font-size: 1.2em;
}

.chart-wrapper-bottom {
  background-color: white;
  color: black;
  text-align: center;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  min-height: 80px;
  padding: 0 10px 5px 10px;
  /* padding-bottom: 5px; */
}

.chart-wrapper-bottom>span {
  display: block;
  padding-top: 15px;
}

.chart-wrapper-bottom .swiper-container {
  min-height: 80px;
}

.chart-wrapper-bottom .swiper-container .single-bottom-text {
  padding-bottom: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chart-wrapper-bottom .swiper-container-horizontal>.swiper-pagination-bullets {
  position: relative;
}

.chart-wrapper-bottom .single-bottom-text .bold {
  font-size: 1.2em;
}

.chart-wrapper-progress-circle {
  border-radius: 0;
}

.chart-wrapper-progress-circle-bottom>span {
  padding: 0;
}

.chart-wrapper-progress-circle-bottom>.bold {
  padding-top: 15px;
  font-size: 1.2em;
}

.chart-wrapper-bottom>.row,
.chart-wrapper-bottom .single-bottom-text {
  padding: 0;
  margin: 0;
  padding-top: 5px;
}

.chart-wrapper .progress-circle {
  width: 200px;
  margin: auto;
  padding: 45px 0;
}

.chart-wrapper-progress-circle .CircularProgressbar-path {
  stroke: white !important;
}

.chart-wrapper-progress-circle .CircularProgressbar-trail {
  stroke: rgba(0, 0, 0, 0.2) !important;
}

.chart-wrapper-progress-circle .CircularProgressbar-text {
  fill: white !important;
}

.data-progress-bar {
  transform: rotate(-90deg);
  transform-origin: left;
  min-width: 200px;
  min-height: 60px;
}

.data-progress-bar .progress-bar {
  width: 0;
  animation: progress 1.5s ease-in-out forwards;
}

@keyframes progress {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes show {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.progress-bar-value {
  color: black;
  position: absolute;
  left: 40%;
  transform: rotate(90deg);
  font-size: 1.7em;
  display: block;
}

.single-progress-bar {
  width: 60px;
  top: 200px;
  position: relative;
  display: block;
}

.single-progress-bar:nth-child(odd) {
  float: left;
  margin-left: 25%;
}

.single-progress-bar:nth-child(even) {
  float: right;
  margin-right: 10%;
}

.btn-add-eval {
  border: 1px solid rgba(255, 255, 255, 0.8);
  border-radius: 3px;
  height: 40px;
  line-height: 2.5;
  padding: 0 20px;
  margin: 30px 0px 0px 10px;
  background: transparent;
  color: white;
  width: 100%;
  text-align: left;
  cursor: pointer;
}

.btn-add-eval i {
  float: right;
}

/** User Search **/

.user-search-wrapper {
  width: 30%;
  position: relative;
  float: right;
  height: 100vh;
  z-index: 10;
  background-color: rgb(26, 85, 159);
  font-size: 0.6em;
  padding: 10px;
}

.user-search-wrapper.out {
  display: none;
}

.user-search-wrapper.in {
  display: block;
}

.user-search-wrapper .little-loading-droid {
  display: block;
  height: 50px;
  margin: 30px auto;
  content: url('../../pictures/loading-droid.gif');
}

.user-search-wrapper .menu-back-button {
  top: -10px;
  position: relative;
}

.user-search-input {
  width: 95%;
  border-radius: 3px;
  border: none;
  padding: 10px;
}

.user-search-input::placeholder {
  color: black;
}

.user-search-button {
  background-color: white;
  border: none;
  border-radius: 3px;
  padding: 10px;
  margin-top: 10px;
}

.result-wrapper {
  padding: 10px;
  height: calc(80vh - 160px);
  color: white;
  overflow: hidden;
  overflow-y: auto;
}

.result-wrapper hr {
  border-color: white;
}

.result-wrapper a {
  color: white;
}

.result-wrapper a:hover {
  color: black;
}

/** User Profile **/

.user-profile {
  /* background-color: #1C68C2; */
  background-image: radial-gradient(#077DC5 0%, #0458BF 100%);
  min-height: 160vh;
  padding-bottom: 160px;
}

.user-profile-header-wrapper {
  background: transparent;
  color: white;
  padding: 30px;
}

.user-profile-header-wrapper i {
  font-size: 34px;
  margin: 10px;
  cursor: pointer;
}

.user-profile-header-wrapper a {
  color: white;
}

.user-profile-header-other-info dl,
.user-info-float-wrapper dl {
  margin-bottom: 0;
  line-height: 1;
}

.user-profile-header-other-info dl dt {
  text-align: right;
}

.user-profile-firstname-lastname {
  font-size: 2.3em;
  font-weight: 700;
}

.user-profile-info-button {
  background-color: #1F75D5;
  text-align: center;
  color: white;
  border: 1px solid white;
  border-radius: 3px;
  line-height: 40px;
  cursor: pointer;
  margin: 5px 0;
}

.user-profile-info-button.gdpr {
  margin-bottom: 10px;
}

table.user-profile-table {
  border: 1px solid #1C6EA4;
  background-color: #EEEEEE;
  width: 90%;
  text-align: center;
  border-collapse: collapse;
}

table.user-profile-table td,
table.user-profile-table th {
  border: 1px solid #AAAAAA;
  padding: 3px 10px;
}

table.user-profile-table tbody td {
  font-size: 13px;
}

table.user-profile-table td:first-child {
  text-align: right;
}

table.user-profile-table td:last-child {
  text-align: left;
}

table.user-profile-table tr:nth-child(even) {
  background: #D0E4F5;
}

table.user-profile-table thead {
  background: #1C6EA4;
  background: -moz-linear-gradient(top, #5592bb 0%, #327cad 66%, #1C6EA4 100%);
  background: -webkit-linear-gradient(top, #5592bb 0%, #327cad 66%, #1C6EA4 100%);
  background: linear-gradient(to bottom, #5592bb 0%, #327cad 66%, #1C6EA4 100%);
  border-bottom: 2px solid #444444;
}

table.user-profile-table thead th {
  font-size: 15px;
  font-weight: bold;
  color: #FFFFFF;
  border-left: 2px solid #D0E4F5;
}

table.user-profile-table thead th:first-child {
  border-left: none;
}

table.user-profile-table tfoot td {
  font-size: 14px;
}

table.user-profile-table tfoot .links {
  text-align: right;
}

table.user-profile-table tfoot .links a {
  display: inline-block;
  background: #1C6EA4;
  color: #FFFFFF;
  padding: 2px 8px;
  border-radius: 3px;
}

span.larger {
  font-size: 1.2em;
}

/** User Profile Timeline **/

.timeline-tabs-wrapper {
  /* background-color: #1C68C2; */
  color: white;
  width: 95%;
  margin: 0 auto;
}

.user-info-float-wrapper {
  background-color: white;
  position: absolute;
  top: 352px;
  border-radius: 3px;
  width: 280px;
  margin-left: 10px;
  padding: 10px;
}

.switch-wrapper-data button.rc-switch {
  transform: none;
  -webkit-transform: none;
  background-color: rgb(255, 149, 0);
}

.switch-wrapper-data button.rc-switch-checked {
  background-color: rgb(76, 217, 100);
}

.interessen-span {
  font-weight: 800;
  padding-bottom: 5px;
  display: block;
  position: relative;
}

.interests {
  background-color: #96c7ff;
  padding: 10px;
  border-radius: 3px;
}

.timeline-tabs-wrapper .tab.active {
  border: 1px solid white;
}

.timeline-tabs-wrapper .tab {
  background-color: #1F75D5;
  text-align: center;
  border: 1px solid #1F75D5;
  margin: 0 10px;
  border-radius: 3px;
  line-height: 40px;
  cursor: pointer;
}

.timeline-header {
  margin: 20px 30px 0px 300px;
  color: white;
  font-size: 1.5em;
}

.timeline-wrapper {
  background-color: white;
  margin: 20px 30px 0px 300px;
  border-radius: 3px;
  padding: 10px;
}

.timeline-wrapper .single-timeline {
  color: black;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 5px 5px;
}

/* CRM Cards */

.crm-card-wrapper {
  background: #f9f9f9;
  color: rgb(25, 93, 186);
  width: 90vw;
  margin: 0 auto;
  border-radius: 3px;
}

.crm-card-wrapper.print-address,
.crm-card-wrapper.card-sms {
  margin: 20px auto;
}

.crm-card-single {
  border: 1px solid rgb(25, 93, 186);
  border-top: none;
  border-bottom: none;
  padding: 10px;
  text-align: center;
}

.crm-card-single:first-child {
  border-left: none;
}

.crm-card-single:last-child {
  border-right: none;
}

.crm-card-head,
.crm-card-number {
  font-weight: 600;
  font-size: 1.5em;
}

.crm-card-header-text {
  position: relative;
  display: block;
  left: 5vw;
  margin-top: 10px;
  font-size: 1.5em;
}

.crm-card-value {
  font-size: 1em;
}

/* Large devices (desktops, less than 1200px) */

@media (max-width: 1199.98px) {}

/* Medium devices (tablets, less than 992px) */

@media (max-width: 991.98px) {
  .crm-map {
    display: none;
  }
}

/* Small devices (landscape phones, less than 768px) */

@media (max-width: 767.98px) {
  .user-search-wrapper {
    width: 50%;
  }

  .timeline-header {
    margin: 40px 30px 0px 20px;
  }

  .timeline-wrapper {
    margin: 20px 30px 0 20px;
  }

  .user-info-float-wrapper {
    position: relative;
    top: 20px;
    width: 95%;
  }

  .user-search-wrapper.fade-enter-done {
    width: 100%;
  }

  .data-header-text {
    font-size: 24px;
  }

  .data-header i {
    font-size: 24px;
  }

  .data-header-bottom .DateRangePicker {
    padding: 0;
  }

  .data-header-bottom .time-span-text {
    display: none;
  }

  .data-header-bottom .show-charts-mobile {
    display: block;
  }

  .data-charts-single .col-sm-12 {
    padding-top: 10px;
  }

  .timespan-datepicker {
    width: 100%;
  }

  .time-span-text {
    display: none;
  }

  .timespan-datepicker .data-timespan-select__control,
  .timespan-datepicker .data-timespan-select__control--is-focused {
    width: 120px;
    margin-left: 5px;
  }

  .data-charts-single .left-chart.false,
  .data-charts-single .right-chart.true {
    opacity: 0;
    transition: 1s all ease;
  }

  .data-charts-single .left-chart.true,
  .data-charts-single .right-chart.false {
    opacity: 1;
    transition: 1s all ease;
  }

  .data-charts-single .react-card-back {
    z-index: 5;
  }

  .timeline-tabs-wrapper .tab {
    width: 90%;
    margin: 5px auto;
  }

  .btn-add-eval {
    width: 95%;
  }

  .user-profile-header-other-info dl dt {
    text-align: left;
  }

  .crm-card-single {
    border: none;
    border-top: 1px solid rgb(25, 93, 186);
  }

  .crm-card-single:first-child {
    border-top: none;
  }
}

@keyframes shake {

  0%,
  60% {
    transform: rotateY(10deg);
  }

  40%,
  90% {
    transform: rotateY(0deg);
  }
}

/* Extra small devices (portrait phones, less than 576px) */

@media (max-width: 575.98px) {}

@media (max-width: 430px) {

  .timespan-datepicker .data-timespan-select__control,
  .timespan-datepicker .data-timespan-select__control--is-focused {
    display: none;
  }
}