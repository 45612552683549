.droid-menu {
  z-index: 20;
  display: block;
  position: relative;
}

.side-menu-container {
  padding-top: 0;
  height: 100vh;
  width: 380px;
  position: fixed;
  float: right;
  right: 0;
  top: 0px;
  z-index: 12;
  text-align: center;
  /* transition: top 0.5s, left 0.5s, width 0.5s, height 0.5s;
  -webkit-transition: top 0.5s, left 0.5s, width 0.5s, height 0.5s; */
}

.side-menu-container-head {
  z-index: 20;
}

.side-menu-header {
  padding: 40px 0 20px 0;
  pointer-events: none;
}

.side-menu-header img {
  width: 50px;
  margin: 10px;
}

.side-menu-header h5 {
  letter-spacing: 5px;
}

.menu-back-button {
  text-align: left;
  top: 30px;
  position: absolute;
  font-size: 2em;
}

.fade-enter {
  opacity: 0.01;
  /* transform: translateY(50%); */
  /* transform: translateX(50%); */
  width: 0;
  z-index: 150;
}

.fade-enter-done {
  opacity: 1;
  float: right;
  /* transform: translateX(none); */
  transition: all 0.5s ease;
  width: 380px;
  z-index: 150;
}

.fade-exit {
  opacity: 1;
  /* transform: translateX(50%); */
  transition: all 0.5s ease;
}

.fade-exit-done {
  opacity: 1;
  /* transform: translateX(none); */
  transition: all 0.5s ease;
  padding: 0;
  margin: 0;
}

/** Data Menu **/

.data-menu-inner-single i {
  font-size: 24px;
}

.data-menu-inner hr {
  border-color: rgba(255, 255, 255, 0.5);
  margin: 10px 15px;
}

.data-menu-inner a {
  color: white;
  text-decoration: none;
}

.data-menu-inner-text {
  text-align: left;
  font-size: 0.8em;
  opacity: 0.7;
  left: 0;
  padding: 15px;
  display: block;
}

.side-menu-container-data {
  background-image: radial-gradient(circle 679px at 51.3% 100.9%, #077dc5 0%, #0458bf 100%);
}

/** Audience Menu **/

.side-menu-container-audience {
  background-color: #69196e;
  color: white;
}

/** Journey Menu **/

.side-menu-container-journey {
  background-color: #156D6C;
  color: white;
}

.single-journies-wrapper {
  max-height: 40vh;
  overflow: scroll;
  padding: 0 20px;
}

.journey-btn {
  width: 90%;
  height: 40px;
  background: transparent;
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.8);
  color: white;
  margin: 10px 0 20px 0;
}

/** Delivery Menu **/

.side-menu-container-delivery {
  background-color: #950637;
  color: white;
}

/** Settings Menu **/

.side-menu-container-settings {
  background-color: #243E5C;
  color: white;
}

.settings-info {
  padding-top: 20px;
  margin: 0 10px;
}

.settings-info hr {
  border-top: 1px solid white;
  width: 90%;
}

.settings-btn {
  background: white;
  border: none;
  margin: 20px 0;
  padding: 5px 30px;
  cursor: pointer;
}

.settings-menu-info-wrapper {
  overflow-y: scroll;
  position: relative;
  display: block;
  height: 60vh;
}

#lang-switch {
  margin: 20px;
}

#lang-switch div.journey-channel-select__control {
  background: white !important;
}

@media (max-width: 767.98px) {
  .side-menu-container {
    width: 100%;
  }
}