.droid-module.login {
  background: #203558;
  color: white;
  text-align: center;
  height: 100vh;
}

.droid-login-logo span {
  font-size: 3em;
}

.droid-login-form-wrapper {
  margin: 0 auto;
  padding-top: 10vh;
}

.droid-login-form {
  margin-top: 40px;
  z-index: 10;
  position: relative;
  display: block;
}

.droid-login-form input {
  background: transparent;
  border: none;
  border-bottom: 1px solid #16FFFF;
  font-size: 1.5em;
  padding: 10px;
  color: white;
  width: 30vw;
}

.droid-login-form input.correct {
  border-bottom: 1px solid #16FFFF;
}

.droid-login-form input.notcorrect {
  border-bottom: 1px solid red;
}

.droid-login-form input::placeholder {
  color: white;
}

.droid-btn-login {
  background: transparent;
  color: #16FFFF;
  border: 1px solid #16FFFF;
  border-radius: 40px;
  width: 200px;
  height: 50px;
  font-size: 18px;
  font-weight: 300;
  margin-top: 40px;
}

.droid-btn-login:hover {
  color: #182235;
  background: #16FFFF;
}

.login-notify {
  background: #16FFFF;
  color: black;
}

.login-notify-error {
  background: #BC4D4F;
  color: white;
}

.login-notify-progressbar {
  background: white;
}

.info-text-inner.terms-modal {
  max-width: 90vw;
  max-height: 90vh;
  overflow: scroll;
}

/* .terms-modal label {
  float: left;
}

.terms-modal input {
  float: right;
} */

.info-text-inner.terms-modal button {
  margin: 10px auto;
}

.terms-text-header {
  text-align: center;
}

.terms-text-inner li {
  font-weight: 600;
  padding: 10px 0 5px 0;
}

.info-text-inner.terms-modal label {
  margin-left: 40px;
}

.info-text-inner.terms-modal .row.error {
  background: #BC4D4F;
  margin: 0;
  padding: 5px;
  color: white;
  font-weight: 600;
}

/* Large devices (desktops, less than 1200px) */

@media (max-width: 1199.98px) {}

/* Medium devices (tablets, less than 992px) */

@media (max-width: 991.98px) {
  .droid-login-form input {
    width: 80%;
  }
}

/* Small devices (landscape phones, less than 768px) */

@media (max-width: 767.98px) {
  .droid-module.login {
    font-size: 0.8em;
  }
}

/* Extra small devices (portrait phones, less than 576px) */

@media (max-width: 575.98px) {}