@charset "UTF-8";
@font-face {
  font-family: "droid-connector";
  src: url("./fonts/droid-connector.eot");
  src: url("./fonts/droid-connector.eot?#iefix") format("embedded-opentype"), url("./fonts/droid-connector.woff") format("woff"), url("./fonts/droid-connector.ttf") format("truetype"), url("./fonts/droid-connector.svg#droid-connector") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"][data-icon]:before,
[class^=" icon-"][data-icon]:before {
  font-family: "droid-connector" !important;
  content: attr(data-icon);
  font-style: normal !important;
  /*font-weight: normal !important;*/
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "droid-connector" !important;
  font-style: normal !important;
  /*font-weight: normal !important;*/
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-newsletter:before {
  content: "\61";
}

.icon-retargeting:before {
  content: "\62";
}

.icon-onlinebanner:before {
  content: "\63";
}

.icon-direct:before {
  content: "\64";
}

.icon-website:before {
  content: "\65";
}

.icon-sms:before {
  content: "\66";
}

.icon-kaufdaten:before {
  content: "\67";
}

.icon-location:before {
  content: "\68";
}

.icon-apps:before {
  content: "\69";
}

.icon-produktdaten:before {
  content: "\6a";
}

.icon-lookalike:before {
  content: "\6b";
}

.icon-dataenrichment:before {
  content: "\6c";
}

.icon-socialmedia:before {
  content: "\6d";
}

.icon-crm:before {
  content: "\6e";
}