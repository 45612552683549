.journey {
  background-color: #136868;
  min-height: 100vh;
  color: white;
  overflow: hidden;
  padding-bottom: 350px;
}

/*Overlay*/

/* input[type=number] {
  appearance: initial;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  display: block !important;
  position: relative;
  opacity: 1 !important;
  appearance: block !important;
} */

.react-confirm-alert-overlay {
  background: rgba(238, 238, 238, 0.6);
  z-index: 110;
}

.react-confirm-alert-body {
  border-radius: 3px;
  text-align: center;
}

.react-confirm-alert-button-group {
  display: block;
  margin: 0 auto;
  margin-top: 20px;
}

.journey-header {
  position: fixed;
  width: 100%;
  height: 80px;
  color: white;
  background-color: #126363;
  padding: 15px 0;
  width: 100%;
  margin: 0;
  font-size: 2em;
  z-index: 10;
}

.journey-header #journey_back {
  color: white;
  position: relative;
}

.journey-lower-part {
  position: relative;
  top: 80px;
}

.droid-module.journey .side-menu-search,
.droid-module.journey .side-menu-search input,
.droid-module.journey .side-menu-search input::placeholder,
.droid-module.journey .search-result,
.droid-module.journey .side-menu-search .icon-color {
  color: white;
}

.droid-module.journey .side-menu-search .input,
.droid-module.journey .side-menu-search {
  border-color: white;
}

/* .available-journeys-wrapper {
  min-height: 100vh;
  height: 100%;
  background-color: #105C5C;
  font-size: 1em;
  position: absolute;
  z-index: 20;
  right: 0;
  padding: 20px;
} */

.available-journeys-wrapper-header {
  display: block;
  padding-bottom: 20px;
}

.single-journies {
  cursor: pointer;
  font-size: 0.9em;
}

.single-journies-name {
  float: left;
}

.single-journies-status {
  float: right;
}

.single-journies-hr {
  margin: 10px 0;
  border-top: 1px solid white;
}

.journey-main {
  margin-left: 15px;
  margin-right: 15px;
}

.startbar-wrapper {
  /* background: rgba(46, 143, 140, 1) !important;
  background: -moz-linear-gradient(left, rgba(46, 143, 140, 1) 0%, rgba(19, 99, 99, 1) 100%) !important;
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(46, 143, 140, 1)), color-stop(100%, rgba(19, 99, 99, 1))) !important;
  background: -webkit-linear-gradient(left, rgba(46, 143, 140, 1) 0%, rgba(19, 99, 99, 1) 100%) !important;
  background: -o-linear-gradient(left, rgba(46, 143, 140, 1) 0%, rgba(19, 99, 99, 1) 100%) !important;
  background: -ms-linear-gradient(left, rgba(46, 143, 140, 1) 0%, rgba(19, 99, 99, 1) 100%);
  background: linear-gradient(to right, rgba(46, 143, 140, 1) 0%, rgba(19, 99, 99, 1) 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2e8f8c', endColorstr='#136363', GradientType=1); */
  background: #067572;
  width: 100%;
  height: 70px;
  position: relative;
}

.startbar-wrapper button {
  cursor: pointer;
}

.no-sidebar .startbar-wrapper {
  left: 0;
  width: 100%;
}

.startbar-wrapper .little-loading-droid {
  top: 25px;
}

.journey-start-button {
  border-radius: 3px;
  background-color: white;
  border: none;
  position: relative;
  height: 40px;
  width: 110px;
  margin: 15px 0 0 10px;
  color: #136868;
  font-weight: 600;
}

.go-reports-journey {
  background: #4EBEBA;
  border-radius: 3px;
  height: 40px;
  width: 80px;
  position: relative;
  display: block;
  float: right;
  margin: 15px 0 0 10px;
  border: none;
  text-align: center;
  text-decoration: none;
  -webkit-appearance: none !important;
}

.go-reports-journey:hover {
  text-decoration: none;
}

.go-reports-journey span {
  color: white;
  font-weight: 600;
  top: 7px;
  position: relative;
}

.person-count {
  top: 22px;
  position: relative;
}

.btn-journey {
  width: 90%;
  margin: 20px 25px;
  position: relative;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  border-color: #28B3AC;
  color: #28B3AC;
  background-color: transparent;
  white-space: normal;
  text-align: left;
  text-indent: 10px;
  max-width: 250px;
}

.journey_new_artname {
  padding-left: 10px;
}

.SingleDatePicker input {
  background-color: #2ABBB5;
  border: none;
  color: white;
}

.SingleDatePicker input::placeholder {
  color: white;
}

.SingleDatePickerInput {
  border: none;
  background: #2ABBB5;
}

.SingleDatePickerInput_calendarIcon svg {
  fill: white;
}

.btn-reset-datepicker {
  background: transparent;
  border: 1px solid white;
  border-radius: 3px;
  color: white;
  margin: 0 20px;
  width: 70px;
  cursor: pointer;
}

.DateInput__block {
  width: 70%;
}

.btn-journey-save {
  border-color: white;
  color: white;
  float: right;
}

.btn-journey svg {
  float: right;
  top: 5px;
  position: relative;
}

#journey-title-input {
  display: block;
  width: 100%;
  height: 34px;
  font-size: 16px;
  background-color: transparent;
  box-shadow: none;
  border-radius: 0px;
  border: 0px solid green;
  border-bottom: 1px solid
}

.journey-title-wrapper label,
.journey-title-wrapper input,
.journey-title-wrapper input::placeholder {
  color: #2ABBB5;
  font-weight: 600;
}

.journey-select__control {
  background-color: #2ABBB5 !important;
  border: none !important;
  color: white !important;
  height: 60px;
  border-radius: 3px !important;
  cursor: pointer;
}

.journey-select__control--is-focused {
  box-shadow: none !important;
}

.journey-select__placeholder,
.journey-select__single-value {
  color: white !important;
}

.journey-select__indicators {
  display: none !important;
}

.journey-select__menu {
  color: black !important;
}

.journey-start-select {
  height: 60px;
  width: 100%;
  border-radius: 3px;
  background-color: #2ABBB5;
  border: none;
  color: white;
}

.journey-start-select .react-calendar__tile--active {
  background-color: #4EBEBA;
}

.journey-start-select .react-calendar__tile--active:hover {
  background-color: #2ABBB5;
}

.journey-select-label {
  color: #4EBEBA;
  padding-top: 20px;
  padding-left: 3px;
}

.journey-datepicker-wrapper,
.journey-datepicker-wrapper span,
.journey-datepicker-wrapper input,
.journey-datepicker-wrapper button,
.journey-datepicker-wrapper g {
  color: white;
  stroke: white;
  stroke-width: 1.5;
}

.journey-datepicker-wrapper .react-datetime-picker__inputGroup {
  border: none;
  color: white;
  font-weight: 500;
  height: 60px;
}

.react-datetime-picker__wrapper {
  border: none;
}

.react-datetime-picker__inputGroup__leadingZero {
  display: none;
}

.journey-datepicker-wrapper .react-datetime-picker__button__input {
  padding-left: 5px;
}

.react-datetime-picker--disabled {
  background: unset;
  color: unset;
}

.react-datetime-picker__calendar--open,
.react-datetime-picker__calendar--open span,
.react-datetime-picker__calendar--open input,
.react-datetime-picker__calendar--open button {
  color: black;
}

.react-datetime-picker__inputGroup__input:invalid {
  background: rgba(255, 0, 0, 0.8)
}

/* Journey Builder Graphics */

.journey-builder-graphic-wrapper {
  height: 350px;
}

.journey-builder-graphics-img {
  transform: scale(0.6);
  transform-origin: 0 0;
  padding: 20px 0;
}

i.journey-builder {
  color: #034747;
  position: absolute;
  font-size: 36px;
}

i.journey-builder>svg.fa-mobile {
  left: 9px;
  position: relative;
}

.journey-builder0 {
  top: 30px;
  left: 82px;
}

.journey-builder1 {
  top: 30px;
  left: 505px;
}

.journey-builder2 {
  top: 205px;
  left: 302px;
}

.journey-builder3 {
  top: 380px;
  left: 90px;
}

.journey-medium-tags {
  pointer-events: none;
}

/* Journey Builder */

.journey-channel-select {
  margin: 20px 0 20px 20px;
  background: white;
  border-radius: 3px;
  color: black;
}

.journey-channel-select.hours {
  background-color: lightgray;
  height: 50px;
  text-align: center;
}

.journey-channel-select.hours span {
  top: 14px;
  position: relative;
}

.journey-channel-select-handle {
  background-color: #2ABBB5;
  border-radius: 3px 3px 0 0;
  height: 50px;
  padding: 10px;
  font-size: 18px;
  color: white;
}

.journey-channel-select-handle i {
  cursor: pointer;
}

.collapsed .journey-channel-select-handle {
  border-radius: 3px;
}

.journey-channel-select-handle i,
.journey-channel-select-handle svg {
  float: right;
  position: relative;
  top: 5px;
}

.journey-channel-select__menu-list {
  max-height: 200px !important;
  width: auto;
}

.channel-tag-wrapper,
.decision-tag-wrapper {
  float: left;
  margin-right: 10px;
  background: transparent;
  border: 1px solid #82ceca;
}

.channel-tag-wrapper {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.channel-tag,
.decision-tag {
  position: absolute;
  padding-left: 10px;
}

.decision-tag-wrapper {
  width: 25px;
  height: 25px;
  transform: rotate(0.37turn);
}

.decision-tag {
  transform: rotate(-0.37turn);
  padding: 0;
  left: 6px;
  top: -2px;
}

.journey-channel-select-handle .fa-chevron-up {
  display: block;
}

.journey-channel-select-handle .fa-chevron-down {
  display: none;
}

.collapsed .journey-channel-select-handle .fa-chevron-up {
  display: none;
}

.collapsed .journey-channel-select-handle .fa-chevron-down {
  display: block;
}

.journey-channel-select__control,
.journey-channel-select__control--is-focused {
  background-color: lightgray !important;
  border: none !important;
  color: black !important;
  height: 50px;
  margin-top: 20px;
  border-radius: 3px !important;
}

.journey-channel-select__placeholder,
.journey-channel-select__single-value {
  color: black !important;
}

.journey-channel-select__indicators {
  display: none !important;
}

.journey-channel-select__menu {
  color: black !important;
}

div[id*='template-'] .journey-channel-select__menu {
  width: 140% !important;
}

.journey-channel-select-body {
  min-height: 200px;
  padding: 10px 20px;
  /* padding-top: 10px; */
}

.journey-channel-select-body-retargetingbanner {
  min-height: 1000px;
}

.journey-channel-select-body-print {
  min-height: 360px;
}

.journey-channel-select-body-newsletter.j0 {
  min-height: 300px;
}

.journey-channel-select-body-newsletter .ab-test-check {
  min-height: 20px;
  margin-top: 10px;
  position: relative;
}

.journey-channel-select-body-newsletter .ab-test-check label {
  display: inline-block;
  padding: 0 20px 0 0;
}

.journey-channel-select-body-newsletter .ab-test-check input {
  /* float: left; */
  background: #136868;
}

#oldjourney .journey-channel-select-body-print {
  min-height: 200px;
}

.retargeting-select-wrapper .journey-select-label {
  position: relative;
  color: black;
}

.retargeting-select-wrapper .journey-channel-select__control {
  margin: 0;
}

#retargeting-regional .journey-channel-select__control,
#retargeting-day .journey-channel-select__control {
  height: auto;
}

.journey-channel-select__control.pdf-example {
  width: 100%;
}

.custom-ui-pdf-example {
  /* max-width: 90vw; */
  max-height: 90vh;
  overflow: scroll;
  padding-bottom: 100px;
}

.custom-ui-pdf-example img {
  max-width: 80vw;
  max-height: 80vh;
  margin: 20px auto;
  display: block;
}

.custom-ui-pdf-example .journey-channel-select__control {
  width: 50%;
  margin: 0 auto;
  display: block;
}

.retargeting-time-wrapper {
  padding-top: 20px;
}

.retargeting-time-wrapper span {
  display: block;
  margin-top: 13px;
}

.retargeting-time-wrapper .time_picker_preview {
  background-color: lightgray;
}

.retargeting-device-wrapper label {
  display: inline-block;
  margin: 0px 20px 0 0;
}

.retargeting-device-wrapper .rc-checkbox-checked .rc-checkbox-inner,
.retargeting-device-wrapper .rc-checkbox-inner {
  background-color: #067572;
}

.retargeting-duration-budget-wrapper {
  padding-top: 10px;
}

.retargeting-duration-budget-wrapper .row {
  padding-top: 10px;
}

.retargeting-duration-budget-wrapper input {
  background: lightgray;
  border: none;
  padding: 1px;
  border-radius: 3px;
  width: 100%;
}

.pdf-upload-dropzone {
  background: lightgray;
  margin: 20px 0;
  padding: 5px;
  border-radius: 3px;
  text-align: center;
  cursor: pointer;
}

.pdf-upload-dropzone p {
  margin-top: 15px;
  display: block;
  position: relative;
}

div[id*='template-'] {
  width: 70%;
  float: left;
}

.journey-template-preview {
  float: right;
  background: lightgray;
  border-radius: 3px;
  width: 25%;
  top: 20px;
  height: 50px;
  position: relative;
  display: block;
}

#journey-template-sms-input {
  background-color: lightgray;
  border: none;
  color: black;
  height: 50px;
  margin-top: 20px;
  width: 100%;
  border-radius: 3px;
  padding: 0 10px;
}

#journey-template-sms-input::placeholder,
#journey-template-sms-input:placeholder-shown {
  color: black;
}

#journey-template-sms-count {
  margin-left: 5px;
}

.plus-button-wrapper button {
  background: transparent;
  border: none;
  color: white;
  cursor: pointer;
}

.journey-notify {
  background: #2ABBB5;
  color: white;
}

.journey-notify-progressbar {
  background: white;
}

.journey-notify-error {
  background: #a41e1e;
  color: white;
}

/* Large devices (desktops, less than 1200px) */

@media (max-width: 1199.98px) {}

/* Medium devices (tablets, less than 992px) */

@media (max-width: 991.98px) {

  /* .journey-builder-graphics-img {
    zoom: 0.5
  } */
  .journey-channel-select-handle {
    font-size: 16px;
  }
}

/* Small devices (landscape phones, less than 768px) */

@media (max-width: 767.98px) {
  .journey {
    padding-bottom: 320px;
  }

  .journey-header {
    font-size: 24px;
  }

  .journey-header div {
    top: 6px;
  }

  .journey-builder-graphics-img {
    /* zoom: 0.5; */
    margin-top: 50px;
    position: relative;
  }

  .journey-channel-select {
    margin: 10px 0;
  }

  .startbar-wrapper {
    position: fixed;
    bottom: 50px;
    z-index: 10;
    height: 140px;
    text-align: center;
  }

  .btn-journey {
    float: unset;
    margin: 20px auto;
  }

  .journey_new_artname {
    font-size: 24px;
    position: relative;
  }

  .plus-button-wrapper {
    font-size: 1.5rem;
  }

  .journey-start-button {
    width: 90px;
  }
}

/* Extra small devices (portrait phones, less than 576px) */

@media (max-width: 575.98px) {}