@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
/* Global CSS File */

/* @font-face {
  font-family: Source Sans Pro;
  src: url(Source_Sans_Pro/SourceSansPro-Regular.ttf);
} */

body {
  margin: 0;
  padding: 0;
  font-family: 'Source Sans Pro', sans-serif !important;
}

.shadow {
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.3) !important;
}

#root {
  overflow: hidden;
}

.droid-module {
  /* because of Navbar */
  padding-bottom: 100px;
}

/** RC SWITCH CSS **/

.rc-switch {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 44px;
  height: 22px;
  line-height: 20px;
  vertical-align: middle;
  border-radius: 20px 20px;
  border: 1px solid #ccc;
  background-color: #ccc;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}

.rc-switch-inner {
  color: #fff;
  font-size: 12px;
  position: absolute;
  left: 24px;
}

.rc-switch:after {
  position: absolute;
  width: 18px;
  height: 18px;
  left: 2px;
  top: 1px;
  border-radius: 50% 50%;
  background-color: #fff;
  content: " ";
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: left 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  -webkit-animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
          animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: rcSwitchOff;
          animation-name: rcSwitchOff;
}

.rc-switch:hover:after {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-animation-name: rcSwitchOn;
          animation-name: rcSwitchOn;
}

.rc-switch:focus {
  box-shadow: 0 0 0 2px #d5f1fd;
  outline: none;
}

.rc-switch-checked {
  border: 1px solid #87d068;
  background-color: #87d068;
}

.rc-switch-checked .rc-switch-inner {
  left: 6px;
}

.rc-switch-checked:after {
  left: 22px;
}

.rc-switch-disabled {
  cursor: no-drop;
  background: #ccc;
  border-color: #ccc;
}

.rc-switch-disabled:after {
  background: #9e9e9e;
  -webkit-animation-name: none;
          animation-name: none;
  cursor: no-drop;
}

.rc-switch-disabled:hover:after {
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-animation-name: none;
          animation-name: none;
}

.rc-switch-label {
  display: inline-block;
  line-height: 20px;
  font-size: 14px;
  padding-left: 10px;
  vertical-align: middle;
  white-space: normal;
  pointer-events: none;
  -webkit-user-select: text;
  user-select: text;
}

@-webkit-keyframes rcSwitchOn {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.25);
            transform: scale(1.25);
  }

  100% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
}

@keyframes rcSwitchOn {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.25);
            transform: scale(1.25);
  }

  100% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
}

@-webkit-keyframes rcSwitchOff {
  0% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes rcSwitchOff {
  0% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

/** RC-Checkbox CSS **/

/* 一般状态 */

.rc-checkbox {
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  display: inline-block;
  position: relative;
  line-height: 1;
  vertical-align: middle;
}

.rc-checkbox:hover .rc-checkbox-inner,
.rc-checkbox-input:focus+.rc-checkbox-inner {
  border-color: #3dbcf6;
}

.rc-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: inline-block;
  width: 14px;
  height: 14px;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  border-color: #d9d9d9;
  background-color: #ffffff;
  /* transition: border-color 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55), background-color 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55); */
}

.rc-checkbox-inner:after {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  left: 4px;
  top: 1px;
  display: table;
  width: 5px;
  height: 8px;
  border: 2px solid #ffffff;
  border-top: 0;
  border-left: 0;
  content: ' ';
  /* animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55); */
  /* animation-duration: 0.3s; */
  /* animation-name: amCheckboxOut; */
}

.rc-checkbox-input {
  position: absolute;
  left: 0;
  z-index: 9999;
  cursor: pointer;
  opacity: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.rc-checkbox-checked:hover .rc-checkbox-inner {
  border-color: #3dbcf6;
}

.rc-checkbox-checked .rc-checkbox-inner {
  border-color: #3dbcf6;
  background-color: #3dbcf6;
}

.rc-checkbox-checked .rc-checkbox-inner:after {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  position: absolute;
  left: 4px;
  top: 1px;
  display: table;
  width: 5px;
  height: 8px;
  border: 2px solid #ffffff;
  border-top: 0;
  border-left: 0;
  content: ' ';
  /* animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55); */
  /* animation-duration: 0.3s; */
  /* animation-name: amCheckboxOut; */
}

.rc-checkbox-disabled.rc-checkbox-checked:hover .rc-checkbox-inner {
  border-color: #d9d9d9;
}

.rc-checkbox-disabled.rc-checkbox-checked .rc-checkbox-inner {
  background-color: #f3f3f3;
  border-color: #d9d9d9;
}

.rc-checkbox-disabled.rc-checkbox-checked .rc-checkbox-inner:after {
  -webkit-animation-name: none;
          animation-name: none;
  border-color: #cccccc;
}

.rc-checkbox-disabled:hover .rc-checkbox-inner {
  border-color: #d9d9d9;
}

.rc-checkbox-disabled .rc-checkbox-inner {
  border-color: #d9d9d9;
  background-color: #f3f3f3;
}

.rc-checkbox-disabled .rc-checkbox-inner:after {
  -webkit-animation-name: none;
          animation-name: none;
  border-color: #f3f3f3;
}

.rc-checkbox-disabled .rc-checkbox-inner-input {
  cursor: default;
}

@-webkit-keyframes amCheckboxIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(0, 0) rotate(45deg);
            transform: scale(0, 0) rotate(45deg);
  }

  100% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1) rotate(45deg);
            transform: scale(1, 1) rotate(45deg);
  }
}

@keyframes amCheckboxIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(0, 0) rotate(45deg);
            transform: scale(0, 0) rotate(45deg);
  }

  100% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1) rotate(45deg);
            transform: scale(1, 1) rotate(45deg);
  }
}

@-webkit-keyframes amCheckboxOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes amCheckboxOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/** RC-Slider CSS **/

.rc-slider {
  position: relative;
  height: 14px;
  padding: 5px 0;
  width: 100%;
  border-radius: 6px;
  touch-action: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider-rail {
  position: absolute;
  width: 100%;
  background-color: #e9e9e9;
  height: 4px;
  border-radius: 6px;
}

.rc-slider-track {
  position: absolute;
  left: 0;
  height: 4px;
  border-radius: 6px;
  background-color: #abe2fb;
}

.rc-slider-handle {
  position: absolute;
  margin-left: -7px;
  margin-top: -5px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  cursor: grab;
  border-radius: 50%;
  border: solid 2px #96dbfa;
  background-color: #fff;
  touch-action: pan-x;
}

.rc-slider-handle:focus {
  border-color: #57c5f7;
  box-shadow: 0 0 0 5px #96dbfa;
  outline: none;
}

.rc-slider-handle-click-focused:focus {
  border-color: #96dbfa;
  box-shadow: unset;
}

.rc-slider-handle:hover {
  border-color: #57c5f7;
}

.rc-slider-handle:active {
  border-color: #57c5f7;
  box-shadow: 0 0 5px #57c5f7;
  cursor: grabbing;
}

.rc-slider-mark {
  position: absolute;
  top: 18px;
  left: 0;
  width: 100%;
  font-size: 12px;
}

.rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #999;
}

.rc-slider-mark-text-active {
  color: #666;
}

.rc-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
}

.rc-slider-dot {
  position: absolute;
  bottom: -2px;
  margin-left: -4px;
  width: 8px;
  height: 8px;
  border: 2px solid #e9e9e9;
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle;
}

.rc-slider-dot-active {
  border-color: #96dbfa;
}

.rc-slider-disabled {
  background-color: #e9e9e9;
}

.rc-slider-disabled .rc-slider-track {
  background-color: #ccc;
}

.rc-slider-disabled .rc-slider-handle,
.rc-slider-disabled .rc-slider-dot {
  border-color: #ccc;
  box-shadow: none;
  background-color: #fff;
  cursor: not-allowed;
}

.rc-slider-disabled .rc-slider-mark-text,
.rc-slider-disabled .rc-slider-dot {
  cursor: not-allowed !important;
}

.rc-slider-vertical {
  width: 14px;
  height: 100%;
  padding: 0 5px;
}

.rc-slider-vertical .rc-slider-rail {
  height: 100%;
  width: 4px;
}

.rc-slider-vertical .rc-slider-track {
  left: 5px;
  bottom: 0;
  width: 4px;
}

.rc-slider-vertical .rc-slider-handle {
  margin-left: -5px;
  margin-bottom: -7px;
  touch-action: pan-y;
}

.rc-slider-vertical .rc-slider-mark {
  top: 0;
  left: 18px;
  height: 100%;
}

.rc-slider-vertical .rc-slider-step {
  height: 100%;
  width: 4px;
}

.rc-slider-vertical .rc-slider-dot {
  left: 2px;
  margin-bottom: -4px;
}

.rc-slider-vertical .rc-slider-dot:first-child {
  margin-bottom: -4px;
}

.rc-slider-vertical .rc-slider-dot:last-child {
  margin-bottom: -4px;
}

.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  -webkit-animation-duration: .3s;
          animation-duration: .3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  display: block !important;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}

.rc-slider-tooltip-zoom-down-leave {
  -webkit-animation-duration: .3s;
          animation-duration: .3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  display: block !important;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}

.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
.rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
  -webkit-animation-name: rcSliderTooltipZoomDownIn;
          animation-name: rcSliderTooltipZoomDownIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}

.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
  -webkit-animation-name: rcSliderTooltipZoomDownOut;
          animation-name: rcSliderTooltipZoomDownOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}

.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  -webkit-transform: scale(0, 0);
          transform: scale(0, 0);
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.rc-slider-tooltip-zoom-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

@-webkit-keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }

  100% {
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}

@keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }

  100% {
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}

@-webkit-keyframes rcSliderTooltipZoomDownOut {
  0% {
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }

  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
}

@keyframes rcSliderTooltipZoomDownOut {
  0% {
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }

  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
}

.rc-slider-tooltip {
  position: absolute;
  left: -9999px;
  top: -9999px;
  visibility: visible;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider-tooltip * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider-tooltip-hidden {
  display: none;
}

.rc-slider-tooltip-placement-top {
  padding: 4px 0 8px 0;
}

.rc-slider-tooltip-inner {
  padding: 6px 2px;
  min-width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 1;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #6c6c6c;
  border-radius: 6px;
  box-shadow: 0 0 4px #d9d9d9;
}

.rc-slider-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  bottom: 4px;
  left: 50%;
  margin-left: -4px;
  border-width: 4px 4px 0;
  border-top-color: #6c6c6c;
}

/** Progress Bar */

/* Make clicks pass-through */

#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  /* background: #20c997; */
  background: #d5651f;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
}

/* Fancy blur effect */

#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #d5651f, 0 0 5px #d5651f;
  opacity: 1.0;
  -webkit-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */

#nprogress .spinner {
  /* display: block; */
  display: none;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  border: solid 2px transparent;
  border-top-color: #20c997;
  border-left-color: #20c997;
  border-radius: 50%;
  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
html,
body {
  max-height: 100%;
}

.droid-module.main {
  height: 100vh;
  background-color: #243E5C;
  color: white;
}

.inactive {
  opacity: 0.5;
}

.bold {
  font-weight: 600;
}

.semi-bold {
  font-weight: 400;
}

.italic {
  font-style: italic;
}

.thin {
  font-weight: 100;
}

.large {
  font-size: xx-large;
}

.chart-wrapper-bottom .single-bottom-text .large {
  font-size: 2.4em;
}

@media (max-width: 767.98px) {
  .info-text-wrapper {
    display: none !important;
  }

  .navbar-li-creator {
    display: none !important;
  }
}
.droid-module.spider {
  height: 100vh;
  background: radial-gradient(#385B98, #111D2F);
  background: #243E5C;
  color: white;
}

.inner-spider {
  position: relative;
  right: 5%;
  height: 100vh;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  z-index: 10;
}

.spider svg,
.connector svg {
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  pointer-events: none;
}

.connector-icon i[data-fa-i2svg] svg {
  position: unset;
  height: unset;
}

.connector-icon .connector-bolt {
  font-size: 16px;
  color: orange !important;
  left: 5px;
  position: relative;
}

.item {
  overflow: hidden;
  width: 100%;
  color: white;
  display: flex;
  justify-content: center;
  font-size: 3em;
  line-height: 2em;
}

.spider-bubble .bubble .uppercase.bold {
  bottom: 40px;
}

.droid-module .item {
  position: absolute;
}

.item-center {
  top: 45%;
  left: 48.5%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 12 !important;
}

.spider-bubble {
  width: 100%;
  height: 100%;
}

.item-data {
  left: 30%;
  top: 30%;
}

.item-audience {
  left: 48%;
  top: 20%;
}

.item-journey {
  left: 70%;
  top: 35%;
}

.item-creator {
  left: 70%;
  top: 60%;
  z-index: 12;
}

.item-delivery {
  left: 48%;
  top: 70%;
  z-index: 12;
}

.item-reports {
  left: 30%;
  top: 60%;
}

.spider-lines {
  position: relative;
  left: -80px;
  top: 50px;
}

.spider-line {
  stroke-width: 4px;
  stroke: rgb(0, 0, 0);
}

#droid-spider-center-img {
  right: 0.6%;
  position: relative;
}

#data-line {
  stroke: #077DC5;
}

#audience-line {
  stroke: #9E2799;
}

#journey-line {
  stroke: #04A9A0;
}

#creator-line {
  stroke: #D4DEE9;
}

#delivery-line {
  stroke: #B30943;
}

#reports-line {
  stroke: #349B40;
}

@media (max-width: 767.98px) {
  .inner-spider {
    right: 15%;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  .item-data {
    left: 25%;
    top: 35%;
  }
  .item-audience {
    left: 50%;
    top: 25%;
  }
  .item-journey {
    left: 75%;
    top: 35%;
  }
  .item-creator {
    left: 80%;
    top: 55%;
  }
  .item-reports {
    left: 20%;
    top: 58%;
  }
}
.droid-module.login {
  background: #203558;
  color: white;
  text-align: center;
  height: 100vh;
}

.droid-login-logo span {
  font-size: 3em;
}

.droid-login-form-wrapper {
  margin: 0 auto;
  padding-top: 10vh;
}

.droid-login-form {
  margin-top: 40px;
  z-index: 10;
  position: relative;
  display: block;
}

.droid-login-form input {
  background: transparent;
  border: none;
  border-bottom: 1px solid #16FFFF;
  font-size: 1.5em;
  padding: 10px;
  color: white;
  width: 30vw;
}

.droid-login-form input.correct {
  border-bottom: 1px solid #16FFFF;
}

.droid-login-form input.notcorrect {
  border-bottom: 1px solid red;
}

.droid-login-form input::-webkit-input-placeholder {
  color: white;
}

.droid-login-form input::placeholder {
  color: white;
}

.droid-btn-login {
  background: transparent;
  color: #16FFFF;
  border: 1px solid #16FFFF;
  border-radius: 40px;
  width: 200px;
  height: 50px;
  font-size: 18px;
  font-weight: 300;
  margin-top: 40px;
}

.droid-btn-login:hover {
  color: #182235;
  background: #16FFFF;
}

.login-notify {
  background: #16FFFF;
  color: black;
}

.login-notify-error {
  background: #BC4D4F;
  color: white;
}

.login-notify-progressbar {
  background: white;
}

.info-text-inner.terms-modal {
  max-width: 90vw;
  max-height: 90vh;
  overflow: scroll;
}

/* .terms-modal label {
  float: left;
}

.terms-modal input {
  float: right;
} */

.info-text-inner.terms-modal button {
  margin: 10px auto;
}

.terms-text-header {
  text-align: center;
}

.terms-text-inner li {
  font-weight: 600;
  padding: 10px 0 5px 0;
}

.info-text-inner.terms-modal label {
  margin-left: 40px;
}

.info-text-inner.terms-modal .row.error {
  background: #BC4D4F;
  margin: 0;
  padding: 5px;
  color: white;
  font-weight: 600;
}

/* Large devices (desktops, less than 1200px) */

@media (max-width: 1199.98px) {}

/* Medium devices (tablets, less than 992px) */

@media (max-width: 991.98px) {
  .droid-login-form input {
    width: 80%;
  }
}

/* Small devices (landscape phones, less than 768px) */

@media (max-width: 767.98px) {
  .droid-module.login {
    font-size: 0.8em;
  }
}

/* Extra small devices (portrait phones, less than 576px) */

@media (max-width: 575.98px) {}
.data {
  background-color: #1853b3;
  min-height: 100vh;
  color: white;
}

.data-notify {
  background: #1F75D5;
  color: white;
}

.data-notify-progressbar {
  background: white;
}

/** Data Headers */

.data-header {
  background-color: #1F75D5;
  height: 80px;
  font-size: 2em;
  position: fixed;
  width: 100%;
  z-index: 10;
}

.data-header-text {
  position: relative;
  top: 15px;
  padding-left: 30px;
}

.data-header i {
  position: relative;
  top: 15px;
  padding: 0 10px;
}

.data-header a {
  color: white;
}

.data-header-bottom {
  background-color: rgb(25, 93, 186);
  width: 100%;
  height: 70px;
  padding: 10px 0 10px 0;
}

.data-header i {
  cursor: pointer;
}

.data-header-bottom .time-span-text {
  position: relative;
  opacity: 0.8;
  padding-right: 20px;
  top: 3px;
}

.data-header-bottom .show-charts-mobile {
  display: none;
}

.data-header-bottom .data-swiper-wrapper {
  max-width: 200px;
  float: left;
  padding-top: 15px;
  margin-left: 10px;
}

.data-header-bottom .data-swiper-wrapper .single-slide {
  text-align: center;
}

.data-lower-part {
  position: relative;
  top: 80px;
  padding-bottom: 100px;
}

/** DateRangePicker **/

.data-header-bottom .DateRangePicker {
  margin-right: 10px;
}

.data-header-bottom .DateRangePicker .DateRangePickerInput,
.data-header-bottom .DateRangePicker .DateInput,
.data-header-bottom .DateRangePicker .DateInput_input {
  /* background: #0587D6; */
  background: transparent;
  color: white;
  border: 1px solid transparent;
  border-radius: 5px;
  font-weight: 400;
  cursor: pointer;
}

.data-header-bottom svg:not(.DayPickerNavigation_svg__horizontal) {
  fill: white;
}

.data-header-bottom #data-bottom-header-startdate::-webkit-input-placeholder, .data-header-bottom #data-bottom-header-enddate::-webkit-input-placeholder {
  color: white;
}

.data-header-bottom #data-bottom-header-startdate,
.data-header-bottom #data-bottom-header-startdate::placeholder,
.data-header-bottom #data-bottom-header-enddate,
.data-header-bottom #data-bottom-header-enddate::placeholder {
  color: white;
}

.data-header-bottom .DateInput_input__focused {
  border-bottom: 2px solid #28A0FC !important;
  border-radius: 0 !important;
}

.CalendarDay__selected_span {
  background: #82e0aa;
  color: white;
  border: 1px solid white;
}

.CalendarDay__selected {
  background: rgb(25, 93, 186);
  color: white;
}

.CalendarDay__selected:hover {
  background: #1F75D5;
  color: white;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: #28A0FC;
  color: white;
}

.data-header-bottom .CalendarDay__selected_span {
  background: #28A0FC;
}

.timespan-datepicker {
  width: 700px;
  float: right;
}

.timespan-datepicker .data-timespan-select__control,
.timespan-datepicker .data-timespan-select__control--is-focused {
  float: left;
  width: 200px;
  top: 8px;
  background: #1F75D5;
  color: white;
  border: none !important;
  color: white !important;
  box-shadow: none;
  border-radius: 3px;
}

.data-timespan-select__option--is-selected {
  background: #28A0FC !important;
}

.data-timespan-select__option--is-focused {
  background: white !important;
  color: black !important;
}

.data-timespan-select__placeholder,
.data-timespan-select__single-value {
  color: white !important;
}

.data-timespan-select__indicators {
  display: block !important;
}

.data-timespan-select__menu {
  color: white !important;
  text-align: left;
  max-width: 250px;
  background: #1F75D5 !important;
}

/** Charts **/

.data-charts-single {
  padding: 10px;
}

.data-charts-single .react-card-flip {
  width: 100%;
  height: 440px;
}

.chart-wrapper {
  position: relative;
  background-color: #28A0FC;
  height: inherit;
  min-height: 290px;
  border-radius: 3px;
  color: white;
}

.chart-wrapper canvas {
  max-height: 390px;
}

.month-day-filter {
  position: relative;
  top: 0;
  font-size: 0.9em;
}

.droid-data-monthday {
  margin: 0 15px;
  padding: 0 5px;
  cursor: pointer;
}

.droid-data-monthday.active {
  border-bottom: 1px solid #15FFFB;
}

.delivery-background .chart-wrapper {
  background-color: #BC4D4F;
}

.chart-wrapper.line {
  height: 430px;
}

.chart-wrapper.pie {
  border-radius: 0;
}

.chart-wrapper-progress-bar {
  border-radius: 0;
}

.chart-wrapper-progress-bar.hidden {
  display: none;
}

#data-line-chart {
  position: relative;
}

.chart-wrapper-top {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-color: #28A0FC;
  padding: 15px 0;
  font-size: 1.2em;
}

.chart-wrapper-bottom {
  background-color: white;
  color: black;
  text-align: center;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  min-height: 80px;
  padding: 0 10px 5px 10px;
  /* padding-bottom: 5px; */
}

.chart-wrapper-bottom>span {
  display: block;
  padding-top: 15px;
}

.chart-wrapper-bottom .swiper-container {
  min-height: 80px;
}

.chart-wrapper-bottom .swiper-container .single-bottom-text {
  padding-bottom: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chart-wrapper-bottom .swiper-container-horizontal>.swiper-pagination-bullets {
  position: relative;
}

.chart-wrapper-bottom .single-bottom-text .bold {
  font-size: 1.2em;
}

.chart-wrapper-progress-circle {
  border-radius: 0;
}

.chart-wrapper-progress-circle-bottom>span {
  padding: 0;
}

.chart-wrapper-progress-circle-bottom>.bold {
  padding-top: 15px;
  font-size: 1.2em;
}

.chart-wrapper-bottom>.row,
.chart-wrapper-bottom .single-bottom-text {
  padding: 0;
  margin: 0;
  padding-top: 5px;
}

.chart-wrapper .progress-circle {
  width: 200px;
  margin: auto;
  padding: 45px 0;
}

.chart-wrapper-progress-circle .CircularProgressbar-path {
  stroke: white !important;
}

.chart-wrapper-progress-circle .CircularProgressbar-trail {
  stroke: rgba(0, 0, 0, 0.2) !important;
}

.chart-wrapper-progress-circle .CircularProgressbar-text {
  fill: white !important;
}

.data-progress-bar {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  -webkit-transform-origin: left;
          transform-origin: left;
  min-width: 200px;
  min-height: 60px;
}

.data-progress-bar .progress-bar {
  width: 0;
  -webkit-animation: progress 1.5s ease-in-out forwards;
          animation: progress 1.5s ease-in-out forwards;
}

@-webkit-keyframes progress {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes progress {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@-webkit-keyframes show {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes show {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.progress-bar-value {
  color: black;
  position: absolute;
  left: 40%;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  font-size: 1.7em;
  display: block;
}

.single-progress-bar {
  width: 60px;
  top: 200px;
  position: relative;
  display: block;
}

.single-progress-bar:nth-child(odd) {
  float: left;
  margin-left: 25%;
}

.single-progress-bar:nth-child(even) {
  float: right;
  margin-right: 10%;
}

.btn-add-eval {
  border: 1px solid rgba(255, 255, 255, 0.8);
  border-radius: 3px;
  height: 40px;
  line-height: 2.5;
  padding: 0 20px;
  margin: 30px 0px 0px 10px;
  background: transparent;
  color: white;
  width: 100%;
  text-align: left;
  cursor: pointer;
}

.btn-add-eval i {
  float: right;
}

/** User Search **/

.user-search-wrapper {
  width: 30%;
  position: relative;
  float: right;
  height: 100vh;
  z-index: 10;
  background-color: rgb(26, 85, 159);
  font-size: 0.6em;
  padding: 10px;
}

.user-search-wrapper.out {
  display: none;
}

.user-search-wrapper.in {
  display: block;
}

.user-search-wrapper .little-loading-droid {
  display: block;
  height: 50px;
  margin: 30px auto;
  content: url(/static/media/loading-droid.6ed74bc0.gif);
}

.user-search-wrapper .menu-back-button {
  top: -10px;
  position: relative;
}

.user-search-input {
  width: 95%;
  border-radius: 3px;
  border: none;
  padding: 10px;
}

.user-search-input::-webkit-input-placeholder {
  color: black;
}

.user-search-input::placeholder {
  color: black;
}

.user-search-button {
  background-color: white;
  border: none;
  border-radius: 3px;
  padding: 10px;
  margin-top: 10px;
}

.result-wrapper {
  padding: 10px;
  height: calc(80vh - 160px);
  color: white;
  overflow: hidden;
  overflow-y: auto;
}

.result-wrapper hr {
  border-color: white;
}

.result-wrapper a {
  color: white;
}

.result-wrapper a:hover {
  color: black;
}

/** User Profile **/

.user-profile {
  /* background-color: #1C68C2; */
  background-image: radial-gradient(#077DC5 0%, #0458BF 100%);
  min-height: 160vh;
  padding-bottom: 160px;
}

.user-profile-header-wrapper {
  background: transparent;
  color: white;
  padding: 30px;
}

.user-profile-header-wrapper i {
  font-size: 34px;
  margin: 10px;
  cursor: pointer;
}

.user-profile-header-wrapper a {
  color: white;
}

.user-profile-header-other-info dl,
.user-info-float-wrapper dl {
  margin-bottom: 0;
  line-height: 1;
}

.user-profile-header-other-info dl dt {
  text-align: right;
}

.user-profile-firstname-lastname {
  font-size: 2.3em;
  font-weight: 700;
}

.user-profile-info-button {
  background-color: #1F75D5;
  text-align: center;
  color: white;
  border: 1px solid white;
  border-radius: 3px;
  line-height: 40px;
  cursor: pointer;
  margin: 5px 0;
}

.user-profile-info-button.gdpr {
  margin-bottom: 10px;
}

table.user-profile-table {
  border: 1px solid #1C6EA4;
  background-color: #EEEEEE;
  width: 90%;
  text-align: center;
  border-collapse: collapse;
}

table.user-profile-table td,
table.user-profile-table th {
  border: 1px solid #AAAAAA;
  padding: 3px 10px;
}

table.user-profile-table tbody td {
  font-size: 13px;
}

table.user-profile-table td:first-child {
  text-align: right;
}

table.user-profile-table td:last-child {
  text-align: left;
}

table.user-profile-table tr:nth-child(even) {
  background: #D0E4F5;
}

table.user-profile-table thead {
  background: #1C6EA4;
  background: linear-gradient(to bottom, #5592bb 0%, #327cad 66%, #1C6EA4 100%);
  border-bottom: 2px solid #444444;
}

table.user-profile-table thead th {
  font-size: 15px;
  font-weight: bold;
  color: #FFFFFF;
  border-left: 2px solid #D0E4F5;
}

table.user-profile-table thead th:first-child {
  border-left: none;
}

table.user-profile-table tfoot td {
  font-size: 14px;
}

table.user-profile-table tfoot .links {
  text-align: right;
}

table.user-profile-table tfoot .links a {
  display: inline-block;
  background: #1C6EA4;
  color: #FFFFFF;
  padding: 2px 8px;
  border-radius: 3px;
}

span.larger {
  font-size: 1.2em;
}

/** User Profile Timeline **/

.timeline-tabs-wrapper {
  /* background-color: #1C68C2; */
  color: white;
  width: 95%;
  margin: 0 auto;
}

.user-info-float-wrapper {
  background-color: white;
  position: absolute;
  top: 352px;
  border-radius: 3px;
  width: 280px;
  margin-left: 10px;
  padding: 10px;
}

.switch-wrapper-data button.rc-switch {
  transform: none;
  -webkit-transform: none;
  background-color: rgb(255, 149, 0);
}

.switch-wrapper-data button.rc-switch-checked {
  background-color: rgb(76, 217, 100);
}

.interessen-span {
  font-weight: 800;
  padding-bottom: 5px;
  display: block;
  position: relative;
}

.interests {
  background-color: #96c7ff;
  padding: 10px;
  border-radius: 3px;
}

.timeline-tabs-wrapper .tab.active {
  border: 1px solid white;
}

.timeline-tabs-wrapper .tab {
  background-color: #1F75D5;
  text-align: center;
  border: 1px solid #1F75D5;
  margin: 0 10px;
  border-radius: 3px;
  line-height: 40px;
  cursor: pointer;
}

.timeline-header {
  margin: 20px 30px 0px 300px;
  color: white;
  font-size: 1.5em;
}

.timeline-wrapper {
  background-color: white;
  margin: 20px 30px 0px 300px;
  border-radius: 3px;
  padding: 10px;
}

.timeline-wrapper .single-timeline {
  color: black;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 5px 5px;
}

/* CRM Cards */

.crm-card-wrapper {
  background: #f9f9f9;
  color: rgb(25, 93, 186);
  width: 90vw;
  margin: 0 auto;
  border-radius: 3px;
}

.crm-card-wrapper.print-address,
.crm-card-wrapper.card-sms {
  margin: 20px auto;
}

.crm-card-single {
  border: 1px solid rgb(25, 93, 186);
  border-top: none;
  border-bottom: none;
  padding: 10px;
  text-align: center;
}

.crm-card-single:first-child {
  border-left: none;
}

.crm-card-single:last-child {
  border-right: none;
}

.crm-card-head,
.crm-card-number {
  font-weight: 600;
  font-size: 1.5em;
}

.crm-card-header-text {
  position: relative;
  display: block;
  left: 5vw;
  margin-top: 10px;
  font-size: 1.5em;
}

.crm-card-value {
  font-size: 1em;
}

/* Large devices (desktops, less than 1200px) */

@media (max-width: 1199.98px) {}

/* Medium devices (tablets, less than 992px) */

@media (max-width: 991.98px) {
  .crm-map {
    display: none;
  }
}

/* Small devices (landscape phones, less than 768px) */

@media (max-width: 767.98px) {
  .user-search-wrapper {
    width: 50%;
  }

  .timeline-header {
    margin: 40px 30px 0px 20px;
  }

  .timeline-wrapper {
    margin: 20px 30px 0 20px;
  }

  .user-info-float-wrapper {
    position: relative;
    top: 20px;
    width: 95%;
  }

  .user-search-wrapper.fade-enter-done {
    width: 100%;
  }

  .data-header-text {
    font-size: 24px;
  }

  .data-header i {
    font-size: 24px;
  }

  .data-header-bottom .DateRangePicker {
    padding: 0;
  }

  .data-header-bottom .time-span-text {
    display: none;
  }

  .data-header-bottom .show-charts-mobile {
    display: block;
  }

  .data-charts-single .col-sm-12 {
    padding-top: 10px;
  }

  .timespan-datepicker {
    width: 100%;
  }

  .time-span-text {
    display: none;
  }

  .timespan-datepicker .data-timespan-select__control,
  .timespan-datepicker .data-timespan-select__control--is-focused {
    width: 120px;
    margin-left: 5px;
  }

  .data-charts-single .left-chart.false,
  .data-charts-single .right-chart.true {
    opacity: 0;
    transition: 1s all ease;
  }

  .data-charts-single .left-chart.true,
  .data-charts-single .right-chart.false {
    opacity: 1;
    transition: 1s all ease;
  }

  .data-charts-single .react-card-back {
    z-index: 5;
  }

  .timeline-tabs-wrapper .tab {
    width: 90%;
    margin: 5px auto;
  }

  .btn-add-eval {
    width: 95%;
  }

  .user-profile-header-other-info dl dt {
    text-align: left;
  }

  .crm-card-single {
    border: none;
    border-top: 1px solid rgb(25, 93, 186);
  }

  .crm-card-single:first-child {
    border-top: none;
  }
}

@-webkit-keyframes shake {

  0%,
  60% {
    -webkit-transform: rotateY(10deg);
            transform: rotateY(10deg);
  }

  40%,
  90% {
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
}

@keyframes shake {

  0%,
  60% {
    -webkit-transform: rotateY(10deg);
            transform: rotateY(10deg);
  }

  40%,
  90% {
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
}

/* Extra small devices (portrait phones, less than 576px) */

@media (max-width: 575.98px) {}

@media (max-width: 430px) {

  .timespan-datepicker .data-timespan-select__control,
  .timespan-datepicker .data-timespan-select__control--is-focused {
    display: none;
  }
}
.info-text-inner {
  background: white;
  padding: 20px;
  border-radius: 3px;
  min-width: 30vw;
}

.info-text-inner button {
  display: block;
  margin: 0px auto;
  background: #243E5C;
  color: white;
  border: none;
  border-radius: 3px;
  padding: 5px;
}

.info-text-inner-header {
  text-align: center;
}

.droid-module.data .data-header .info-text-wrapper {
  top: -1px;
  left: unset;
  position: relative;
  display: inline;
}

.droid-module.data .info-text-wrapper,
.droid-module.reports .info-text-wrapper {
  top: 18px;
  left: 30px;
  position: absolute;
}
.audience {
  background: #6d1b71;
  background: linear-gradient(to bottom, #6d1b71 0%, #8f278c 100%);
  min-height: 100vh;
  padding-bottom: 120px;
}

.audience-notify {
  background: #6d1b71;
  color: white;
}

.audience-notify-progressbar {
  background: white;
}

.rc-switch {
  -webkit-transform: rotate(90deg) scale(0.8);
          transform: rotate(90deg) scale(0.8);
}

.audience-header {
  background-color: #6d1b71;
  color: white;
  position: fixed;
  width: 100%;
  z-index: 12;
}

.audience-top-header {
  height: 80px;
  line-height: 0.7;
  font-size: 2em;
  padding-top: 30px;
}

.audience-top-header-icon-wrapper {
  text-align: right;
}

.audience-top-header-icon-wrapper .info-text-wrapper {
  display: inline;
}

.audience-top-header-icon-wrapper i {
  top: -7px;
  position: relative;
  padding: 0 10px;
  cursor: pointer;
}

.audience-top-header-text {
  margin: 0 30px;
  font-size: 32px;
}

.audience-bottom-header {
  height: 70px;
  background-color: #4f0d51;
}

.audience-save-btn {
  position: relative;
  color: white;
  height: 30px;
  width: 250px;
  margin: 22px 15px;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  font-size: 16px;
  font-weight: 500;
  border-color: #8E678F;
  background-color: transparent;
  text-align: center;
  cursor: pointer;
}

.audience-delete-btn {
  position: relative;
  color: white;
  height: 30px;
  width: 250px;
  margin: 22px 15px;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  font-size: 16px;
  font-weight: 500;
  border-color: #8E678F;
  background-color: transparent;
  text-align: center;
  cursor: pointer;
}

.audience-save-btn:focus,
.audience-delete-btn:focus {
  outline: none;
}

.audience-save-btn span,
.audience-delete-btn span {
  float: left;
}

.audience-save-btn svg,
.audience-delete-btn svg {
  float: right;
  position: relative;
  top: 4px;
}

.audience-body {
  margin: 0 30px;
  padding-top: 160px;
}

.audience-count-switch {
  width: 100%;
  max-width: 20vw;
  margin: 10px 0;
  padding: 0 10px;
  color: white;
  background-color: #732677;
  border-radius: 3px;
  border-style: solid;
  border-width: 2px;
  border-color: #873488;
  overflow: hidden;
  height: 105px;
  transition-duration: 0.3s;
  font-size: 0.8rem;
  float: right;
  position: fixed;
  right: 30px;
  top: 140px;
  z-index: 10;
}

.audience-count {
  margin: 7px 0;
}

.audience-name-input {
  margin-top: 10px;
  width: 85vw;
  float: left;
}

.audience-name-input input:focus {
  outline: none;
}

.audience-name-input input::-webkit-input-placeholder, .side-menu-search input::-webkit-input-placeholder {
  color: #BA8CBA;
}

.audience-name-input label,
.audience-name-input input,
.audience-name-input input::placeholder,
.side-menu-search input,
.side-menu-search input::placeholder {
  color: #BA8CBA;
}

.audience-name-input input {
  display: block;
  width: 100%;
  height: 34px;
  font-size: 16px;
  background-color: transparent;
  box-shadow: none;
  border-radius: 0px;
  border: 0px solid #BA8CBA;
  border-bottom: 1px solid
}

.audience-count span.count {
  font-size: 24px;
}

.audience-count-switch .count-wrapper {
  margin-top: 25px;
}

.col.enrichment,
.col.audience-switch-anonym {
  display: none;
}

/* CARDS STYLE */

.audience .card-body {
  background-color: rgb(125, 56, 131);
  color: white;
  margin: 5px 15px;
  padding: 0;
  padding-bottom: 5px;
  border-radius: 3px;
}

.audience .card-head {
  text-align: center;
  background-color: #8F3F90;
  margin-bottom: 10px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.card-inner-body .input-group-prepend {
  cursor: pointer;
}

.audience-slider .rc-slider-mark-text {
  color: white;
}

.audience-slider .rc-slider-track {
  background-color: #A858A6;
}

.audience-slider .rc-slider-dot-active {
  background-color: #A858A6;
  border-color: #A858A6;
}

.audience-slider .rc-slider-handle,
.audience-slider .rc-slider-handle-focused {
  border-color: white;
}

.card-body span.form-control {
  height: 100%;
}

div[data-react-beautiful-dnd-droppable] {
  margin: 10px;
  min-width: 280px;
  height: 100%;
}

.single-selection .input-group-text {
  background-color: transparent;
  border: none;
}

.single-selection.disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.5;
}

label {
  display: inherit;
  margin: 0;
}

.selection-name {
  margin: 0 10px;
}

.audience-selection-input {
  position: relative;
  margin-top: 30px;
  margin-left: -120px;
}

.little-loading-droid {
  display: none;
  height: 40px;
  top: 10px;
  position: relative;
  margin: -14px auto;
  content: url(/static/media/loading-droid.6ed74bc0.gif);
}

#audience-postcode-input {
  font-size: 15px;
  font-weight: 500;
  margin-left: 10px;
  width: 100px;
}

/* Checkbox Audience Stye */

.rc-checkbox-inner {
  background-color: #7d3883;
}

.rc-checkbox:hover .rc-checkbox-inner,
.rc-checkbox-input:focus+.rc-checkbox-inner {
  border-color: white;
}

.rc-checkbox-checked:hover .rc-checkbox-inner {
  border-color: white;
}

.rc-checkbox-checked .rc-checkbox-inner {
  border-color: white;
  background-color: #7d3883;
}

.rc-checkbox-inner:after {
  content: none;
}

.rc-checkbox-disabled.rc-checkbox-checked:hover .rc-checkbox-inner,
.rc-checkbox-disabled:hover .rc-checkbox-inner,
.rc-checkbox-disabled .rc-checkbox-inner:after,
.rc-checkbox-disabled.rc-checkbox-checked .rc-checkbox-inner:after {
  border-color: white;
  opacity: 0.1;
}

.rc-checkbox-disabled.rc-checkbox-checked .rc-checkbox-inner,
.rc-checkbox-disabled .rc-checkbox-inner {
  background-color: #7d3883;
  border-color: white;
  opacity: 0.1;
}

.rc-checkbox-disabled,
.rc-checkbox-disabled .rc-checkbox-inner-input,
.rc-checkbox-disabled .rc-checkbox-input {
  cursor: not-allowed;
}

/* Switch Audience Style */

.rc-switch,
.rc-switch-checked {
  border: 1px solid white;
  background-color: #7d3883;
}

.side-menu-logo img {
  height: 45px;
}

.side-menu-logo p {
  margin: 18px;
}

.side-menu-logo span {
  font-family: Raleway, sans-serif;
  color: white;
  letter-spacing: 3px;
}

.side-menu-logo span:nth-of-type(1) {
  font-size: 12pt;
  font-weight: 600;
}

.side-menu-logo span:nth-of-type(2) {
  font-size: 13pt;
  font-weight: 300;
}

.side-menu-container .droid-btn {
  margin: 25px auto;
  height: 40px;
  padding: 10px 10px;
  font-size: 10pt;
}

.side-menu-search {
  padding: 30px 30px;
  min-height: 130px;
  border-top: 1px solid #BA8CBA;
  border-bottom: 1px solid #BA8CBA;
  max-height: 55vh;
  overflow: hidden;
  overflow-y: scroll;
}

.side-menu-search input {
  padding-bottom: 3px;
  font-size: 13pt;
  font-weight: 300;
  border: none;
  width: 300px;
  background: rgba(0, 0, 0, 0);
  outline: none;
  margin-top: 5px;
  color: #8E678F;
}

.side-menu-search .input {
  border-bottom: 1px solid #7d3883;
}

.side-menu-container .title {
  font-size: 10pt;
  color: white;
}

.side-menu-search {
  color: #8E678F;
}

.search-result {
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 20px;
  color: #8E678F;
  list-style: none;
}

.search-result .no-padder {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.search-result div {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.side-menu-container li {
  color: #BA8CBA;
  padding: 5px 0;
  border-bottom: 1px solid #7d3883;
}

.no-padder {
  padding: 0px;
}

.side-menu-container .icon-color {
  color: #BA8CBA;
}

.side-menu-container ul .icon-color {
  color: white;
}

.side-menu-target-list {
  padding: 15px 20px 0px 20px;
  height: 48vh;
  overflow: hidden;
  overflow-y: scroll;
}

.audience-menu-inner li {
  cursor: pointer;
}

.side-menu-container .target-list {
  width: 320px;
  padding: 0 15px;
}

#saveTargetBtn {
  cursor: pointer;
}

.fade-enter {
  opacity: 0.01;
  width: 0;
}

.fade-enter-done {
  opacity: 1;
  transition: width 0.5s ease;
  width: 380px;
}

.fade-exit {
  opacity: 1;
  transition: width 0.5s ease;
  width: 0;
}

.fade-exit-done {
  opacity: 1;
  transition: width 0.5s ease;
  width: 0;
  padding: 0;
}

.fade-exit-done .row {
  display: none;
}

.fade-exit-done .row {
  padding: 0;
}

/* Large devices (desktops, less than 1200px) */

@media (max-width: 1199.98px) {}

/* Medium devices (tablets, less than 992px) */

@media (max-width: 991.98px) {}

/* Small devices (landscape phones, less than 768px) */

@media (max-width: 767.98px) {
  .audience {
    padding-bottom: 200px;
  }

  .audience-count-switch {
    width: 80%;
    height: 80px;
    margin: 0 auto;
    position: fixed;
    bottom: 60px;
    z-index: 10;
    max-width: 800px;
    left: 5px;
    right: 0;
    top: unset;
  }

  .audience-count-switch .audience-count {
    margin: -15px 0;
  }

  .audience-top-header-text {
    font-size: 24px;
  }

  .audience-top-header {
    font-size: 24px;
    top: 3px;
    position: relative;
  }

  .audience-top-header .col-8 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .audience-bottom-header {
    text-align: center;
  }

  div[data-react-beautiful-dnd-droppable] {
    min-width: 95%;
  }

  .card-body {
    margin: 0 !important;
  }

  .side-menu-container.fade-enter-done {
    width: 380px;
    z-index: 90;
  }
}

/* Extra small devices (portrait phones, less than 576px) */

@media (max-width: 575.98px) {}
.droid-menu {
  z-index: 20;
  display: block;
  position: relative;
}

.side-menu-container {
  padding-top: 0;
  height: 100vh;
  width: 380px;
  position: fixed;
  float: right;
  right: 0;
  top: 0px;
  z-index: 12;
  text-align: center;
  /* transition: top 0.5s, left 0.5s, width 0.5s, height 0.5s;
  -webkit-transition: top 0.5s, left 0.5s, width 0.5s, height 0.5s; */
}

.side-menu-container-head {
  z-index: 20;
}

.side-menu-header {
  padding: 40px 0 20px 0;
  pointer-events: none;
}

.side-menu-header img {
  width: 50px;
  margin: 10px;
}

.side-menu-header h5 {
  letter-spacing: 5px;
}

.menu-back-button {
  text-align: left;
  top: 30px;
  position: absolute;
  font-size: 2em;
}

.fade-enter {
  opacity: 0.01;
  /* transform: translateY(50%); */
  /* transform: translateX(50%); */
  width: 0;
  z-index: 150;
}

.fade-enter-done {
  opacity: 1;
  float: right;
  /* transform: translateX(none); */
  transition: all 0.5s ease;
  width: 380px;
  z-index: 150;
}

.fade-exit {
  opacity: 1;
  /* transform: translateX(50%); */
  transition: all 0.5s ease;
}

.fade-exit-done {
  opacity: 1;
  /* transform: translateX(none); */
  transition: all 0.5s ease;
  padding: 0;
  margin: 0;
}

/** Data Menu **/

.data-menu-inner-single i {
  font-size: 24px;
}

.data-menu-inner hr {
  border-color: rgba(255, 255, 255, 0.5);
  margin: 10px 15px;
}

.data-menu-inner a {
  color: white;
  text-decoration: none;
}

.data-menu-inner-text {
  text-align: left;
  font-size: 0.8em;
  opacity: 0.7;
  left: 0;
  padding: 15px;
  display: block;
}

.side-menu-container-data {
  background-image: radial-gradient(circle 679px at 51.3% 100.9%, #077dc5 0%, #0458bf 100%);
}

/** Audience Menu **/

.side-menu-container-audience {
  background-color: #69196e;
  color: white;
}

/** Journey Menu **/

.side-menu-container-journey {
  background-color: #156D6C;
  color: white;
}

.single-journies-wrapper {
  max-height: 40vh;
  overflow: scroll;
  padding: 0 20px;
}

.journey-btn {
  width: 90%;
  height: 40px;
  background: transparent;
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.8);
  color: white;
  margin: 10px 0 20px 0;
}

/** Delivery Menu **/

.side-menu-container-delivery {
  background-color: #950637;
  color: white;
}

/** Settings Menu **/

.side-menu-container-settings {
  background-color: #243E5C;
  color: white;
}

.settings-info {
  padding-top: 20px;
  margin: 0 10px;
}

.settings-info hr {
  border-top: 1px solid white;
  width: 90%;
}

.settings-btn {
  background: white;
  border: none;
  margin: 20px 0;
  padding: 5px 30px;
  cursor: pointer;
}

.settings-menu-info-wrapper {
  overflow-y: scroll;
  position: relative;
  display: block;
  height: 60vh;
}

#lang-switch {
  margin: 20px;
}

#lang-switch div.journey-channel-select__control {
  background: white !important;
}

@media (max-width: 767.98px) {
  .side-menu-container {
    width: 100%;
  }
}
@charset "UTF-8";
@font-face {
  font-family: "droid-connector";
  src: url(/static/media/droid-connector.68ecae8c.eot);
  src: url(/static/media/droid-connector.68ecae8c.eot?#iefix) format("embedded-opentype"), url(/static/media/droid-connector.6e61c5f9.woff) format("woff"), url(/static/media/droid-connector.77fdaf11.ttf) format("truetype"), url(/static/media/droid-connector.d6a16942.svg#droid-connector) format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"][data-icon]:before,
[class^=" icon-"][data-icon]:before {
  font-family: "droid-connector" !important;
  content: attr(data-icon);
  font-style: normal !important;
  /*font-weight: normal !important;*/
  -webkit-font-feature-settings: normal !important;
          font-feature-settings: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "droid-connector" !important;
  font-style: normal !important;
  /*font-weight: normal !important;*/
  -webkit-font-feature-settings: normal !important;
          font-feature-settings: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-newsletter:before {
  content: "\61";
}

.icon-retargeting:before {
  content: "\62";
}

.icon-onlinebanner:before {
  content: "\63";
}

.icon-direct:before {
  content: "\64";
}

.icon-website:before {
  content: "\65";
}

.icon-sms:before {
  content: "\66";
}

.icon-kaufdaten:before {
  content: "\67";
}

.icon-location:before {
  content: "\68";
}

.icon-apps:before {
  content: "\69";
}

.icon-produktdaten:before {
  content: "\6a";
}

.icon-lookalike:before {
  content: "\6b";
}

.icon-dataenrichment:before {
  content: "\6c";
}

.icon-socialmedia:before {
  content: "\6d";
}

.icon-crm:before {
  content: "\6e";
}
.journey {
  background-color: #136868;
  min-height: 100vh;
  color: white;
  overflow: hidden;
  padding-bottom: 350px;
}

/*Overlay*/

/* input[type=number] {
  appearance: initial;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  display: block !important;
  position: relative;
  opacity: 1 !important;
  appearance: block !important;
} */

.react-confirm-alert-overlay {
  background: rgba(238, 238, 238, 0.6);
  z-index: 110;
}

.react-confirm-alert-body {
  border-radius: 3px;
  text-align: center;
}

.react-confirm-alert-button-group {
  display: block;
  margin: 0 auto;
  margin-top: 20px;
}

.journey-header {
  position: fixed;
  width: 100%;
  height: 80px;
  color: white;
  background-color: #126363;
  padding: 15px 0;
  width: 100%;
  margin: 0;
  font-size: 2em;
  z-index: 10;
}

.journey-header #journey_back {
  color: white;
  position: relative;
}

.journey-lower-part {
  position: relative;
  top: 80px;
}

.droid-module.journey .side-menu-search input::-webkit-input-placeholder {
  color: white;
}

.droid-module.journey .side-menu-search,
.droid-module.journey .side-menu-search input,
.droid-module.journey .side-menu-search input::placeholder,
.droid-module.journey .search-result,
.droid-module.journey .side-menu-search .icon-color {
  color: white;
}

.droid-module.journey .side-menu-search .input,
.droid-module.journey .side-menu-search {
  border-color: white;
}

/* .available-journeys-wrapper {
  min-height: 100vh;
  height: 100%;
  background-color: #105C5C;
  font-size: 1em;
  position: absolute;
  z-index: 20;
  right: 0;
  padding: 20px;
} */

.available-journeys-wrapper-header {
  display: block;
  padding-bottom: 20px;
}

.single-journies {
  cursor: pointer;
  font-size: 0.9em;
}

.single-journies-name {
  float: left;
}

.single-journies-status {
  float: right;
}

.single-journies-hr {
  margin: 10px 0;
  border-top: 1px solid white;
}

.journey-main {
  margin-left: 15px;
  margin-right: 15px;
}

.startbar-wrapper {
  /* background: rgba(46, 143, 140, 1) !important;
  background: -moz-linear-gradient(left, rgba(46, 143, 140, 1) 0%, rgba(19, 99, 99, 1) 100%) !important;
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(46, 143, 140, 1)), color-stop(100%, rgba(19, 99, 99, 1))) !important;
  background: -webkit-linear-gradient(left, rgba(46, 143, 140, 1) 0%, rgba(19, 99, 99, 1) 100%) !important;
  background: -o-linear-gradient(left, rgba(46, 143, 140, 1) 0%, rgba(19, 99, 99, 1) 100%) !important;
  background: -ms-linear-gradient(left, rgba(46, 143, 140, 1) 0%, rgba(19, 99, 99, 1) 100%);
  background: linear-gradient(to right, rgba(46, 143, 140, 1) 0%, rgba(19, 99, 99, 1) 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2e8f8c', endColorstr='#136363', GradientType=1); */
  background: #067572;
  width: 100%;
  height: 70px;
  position: relative;
}

.startbar-wrapper button {
  cursor: pointer;
}

.no-sidebar .startbar-wrapper {
  left: 0;
  width: 100%;
}

.startbar-wrapper .little-loading-droid {
  top: 25px;
}

.journey-start-button {
  border-radius: 3px;
  background-color: white;
  border: none;
  position: relative;
  height: 40px;
  width: 110px;
  margin: 15px 0 0 10px;
  color: #136868;
  font-weight: 600;
}

.go-reports-journey {
  background: #4EBEBA;
  border-radius: 3px;
  height: 40px;
  width: 80px;
  position: relative;
  display: block;
  float: right;
  margin: 15px 0 0 10px;
  border: none;
  text-align: center;
  text-decoration: none;
  -webkit-appearance: none !important;
}

.go-reports-journey:hover {
  text-decoration: none;
}

.go-reports-journey span {
  color: white;
  font-weight: 600;
  top: 7px;
  position: relative;
}

.person-count {
  top: 22px;
  position: relative;
}

.btn-journey {
  width: 90%;
  margin: 20px 25px;
  position: relative;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  border-color: #28B3AC;
  color: #28B3AC;
  background-color: transparent;
  white-space: normal;
  text-align: left;
  text-indent: 10px;
  max-width: 250px;
}

.journey_new_artname {
  padding-left: 10px;
}

.SingleDatePicker input {
  background-color: #2ABBB5;
  border: none;
  color: white;
}

.SingleDatePicker input::-webkit-input-placeholder {
  color: white;
}

.SingleDatePicker input::placeholder {
  color: white;
}

.SingleDatePickerInput {
  border: none;
  background: #2ABBB5;
}

.SingleDatePickerInput_calendarIcon svg {
  fill: white;
}

.btn-reset-datepicker {
  background: transparent;
  border: 1px solid white;
  border-radius: 3px;
  color: white;
  margin: 0 20px;
  width: 70px;
  cursor: pointer;
}

.DateInput__block {
  width: 70%;
}

.btn-journey-save {
  border-color: white;
  color: white;
  float: right;
}

.btn-journey svg {
  float: right;
  top: 5px;
  position: relative;
}

#journey-title-input {
  display: block;
  width: 100%;
  height: 34px;
  font-size: 16px;
  background-color: transparent;
  box-shadow: none;
  border-radius: 0px;
  border: 0px solid green;
  border-bottom: 1px solid
}

.journey-title-wrapper input::-webkit-input-placeholder {
  color: #2ABBB5;
  font-weight: 600;
}

.journey-title-wrapper label,
.journey-title-wrapper input,
.journey-title-wrapper input::placeholder {
  color: #2ABBB5;
  font-weight: 600;
}

.journey-select__control {
  background-color: #2ABBB5 !important;
  border: none !important;
  color: white !important;
  height: 60px;
  border-radius: 3px !important;
  cursor: pointer;
}

.journey-select__control--is-focused {
  box-shadow: none !important;
}

.journey-select__placeholder,
.journey-select__single-value {
  color: white !important;
}

.journey-select__indicators {
  display: none !important;
}

.journey-select__menu {
  color: black !important;
}

.journey-start-select {
  height: 60px;
  width: 100%;
  border-radius: 3px;
  background-color: #2ABBB5;
  border: none;
  color: white;
}

.journey-start-select .react-calendar__tile--active {
  background-color: #4EBEBA;
}

.journey-start-select .react-calendar__tile--active:hover {
  background-color: #2ABBB5;
}

.journey-select-label {
  color: #4EBEBA;
  padding-top: 20px;
  padding-left: 3px;
}

.journey-datepicker-wrapper,
.journey-datepicker-wrapper span,
.journey-datepicker-wrapper input,
.journey-datepicker-wrapper button,
.journey-datepicker-wrapper g {
  color: white;
  stroke: white;
  stroke-width: 1.5;
}

.journey-datepicker-wrapper .react-datetime-picker__inputGroup {
  border: none;
  color: white;
  font-weight: 500;
  height: 60px;
}

.react-datetime-picker__wrapper {
  border: none;
}

.react-datetime-picker__inputGroup__leadingZero {
  display: none;
}

.journey-datepicker-wrapper .react-datetime-picker__button__input {
  padding-left: 5px;
}

.react-datetime-picker--disabled {
  background: unset;
  color: unset;
}

.react-datetime-picker__calendar--open,
.react-datetime-picker__calendar--open span,
.react-datetime-picker__calendar--open input,
.react-datetime-picker__calendar--open button {
  color: black;
}

.react-datetime-picker__inputGroup__input:invalid {
  background: rgba(255, 0, 0, 0.8)
}

/* Journey Builder Graphics */

.journey-builder-graphic-wrapper {
  height: 350px;
}

.journey-builder-graphics-img {
  -webkit-transform: scale(0.6);
          transform: scale(0.6);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  padding: 20px 0;
}

i.journey-builder {
  color: #034747;
  position: absolute;
  font-size: 36px;
}

i.journey-builder>svg.fa-mobile {
  left: 9px;
  position: relative;
}

.journey-builder0 {
  top: 30px;
  left: 82px;
}

.journey-builder1 {
  top: 30px;
  left: 505px;
}

.journey-builder2 {
  top: 205px;
  left: 302px;
}

.journey-builder3 {
  top: 380px;
  left: 90px;
}

.journey-medium-tags {
  pointer-events: none;
}

/* Journey Builder */

.journey-channel-select {
  margin: 20px 0 20px 20px;
  background: white;
  border-radius: 3px;
  color: black;
}

.journey-channel-select.hours {
  background-color: lightgray;
  height: 50px;
  text-align: center;
}

.journey-channel-select.hours span {
  top: 14px;
  position: relative;
}

.journey-channel-select-handle {
  background-color: #2ABBB5;
  border-radius: 3px 3px 0 0;
  height: 50px;
  padding: 10px;
  font-size: 18px;
  color: white;
}

.journey-channel-select-handle i {
  cursor: pointer;
}

.collapsed .journey-channel-select-handle {
  border-radius: 3px;
}

.journey-channel-select-handle i,
.journey-channel-select-handle svg {
  float: right;
  position: relative;
  top: 5px;
}

.journey-channel-select__menu-list {
  max-height: 200px !important;
  width: auto;
}

.channel-tag-wrapper,
.decision-tag-wrapper {
  float: left;
  margin-right: 10px;
  background: transparent;
  border: 1px solid #82ceca;
}

.channel-tag-wrapper {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.channel-tag,
.decision-tag {
  position: absolute;
  padding-left: 10px;
}

.decision-tag-wrapper {
  width: 25px;
  height: 25px;
  -webkit-transform: rotate(0.37turn);
          transform: rotate(0.37turn);
}

.decision-tag {
  -webkit-transform: rotate(-0.37turn);
          transform: rotate(-0.37turn);
  padding: 0;
  left: 6px;
  top: -2px;
}

.journey-channel-select-handle .fa-chevron-up {
  display: block;
}

.journey-channel-select-handle .fa-chevron-down {
  display: none;
}

.collapsed .journey-channel-select-handle .fa-chevron-up {
  display: none;
}

.collapsed .journey-channel-select-handle .fa-chevron-down {
  display: block;
}

.journey-channel-select__control,
.journey-channel-select__control--is-focused {
  background-color: lightgray !important;
  border: none !important;
  color: black !important;
  height: 50px;
  margin-top: 20px;
  border-radius: 3px !important;
}

.journey-channel-select__placeholder,
.journey-channel-select__single-value {
  color: black !important;
}

.journey-channel-select__indicators {
  display: none !important;
}

.journey-channel-select__menu {
  color: black !important;
}

div[id*='template-'] .journey-channel-select__menu {
  width: 140% !important;
}

.journey-channel-select-body {
  min-height: 200px;
  padding: 10px 20px;
  /* padding-top: 10px; */
}

.journey-channel-select-body-retargetingbanner {
  min-height: 1000px;
}

.journey-channel-select-body-print {
  min-height: 360px;
}

.journey-channel-select-body-newsletter.j0 {
  min-height: 300px;
}

.journey-channel-select-body-newsletter .ab-test-check {
  min-height: 20px;
  margin-top: 10px;
  position: relative;
}

.journey-channel-select-body-newsletter .ab-test-check label {
  display: inline-block;
  padding: 0 20px 0 0;
}

.journey-channel-select-body-newsletter .ab-test-check input {
  /* float: left; */
  background: #136868;
}

#oldjourney .journey-channel-select-body-print {
  min-height: 200px;
}

.retargeting-select-wrapper .journey-select-label {
  position: relative;
  color: black;
}

.retargeting-select-wrapper .journey-channel-select__control {
  margin: 0;
}

#retargeting-regional .journey-channel-select__control,
#retargeting-day .journey-channel-select__control {
  height: auto;
}

.journey-channel-select__control.pdf-example {
  width: 100%;
}

.custom-ui-pdf-example {
  /* max-width: 90vw; */
  max-height: 90vh;
  overflow: scroll;
  padding-bottom: 100px;
}

.custom-ui-pdf-example img {
  max-width: 80vw;
  max-height: 80vh;
  margin: 20px auto;
  display: block;
}

.custom-ui-pdf-example .journey-channel-select__control {
  width: 50%;
  margin: 0 auto;
  display: block;
}

.retargeting-time-wrapper {
  padding-top: 20px;
}

.retargeting-time-wrapper span {
  display: block;
  margin-top: 13px;
}

.retargeting-time-wrapper .time_picker_preview {
  background-color: lightgray;
}

.retargeting-device-wrapper label {
  display: inline-block;
  margin: 0px 20px 0 0;
}

.retargeting-device-wrapper .rc-checkbox-checked .rc-checkbox-inner,
.retargeting-device-wrapper .rc-checkbox-inner {
  background-color: #067572;
}

.retargeting-duration-budget-wrapper {
  padding-top: 10px;
}

.retargeting-duration-budget-wrapper .row {
  padding-top: 10px;
}

.retargeting-duration-budget-wrapper input {
  background: lightgray;
  border: none;
  padding: 1px;
  border-radius: 3px;
  width: 100%;
}

.pdf-upload-dropzone {
  background: lightgray;
  margin: 20px 0;
  padding: 5px;
  border-radius: 3px;
  text-align: center;
  cursor: pointer;
}

.pdf-upload-dropzone p {
  margin-top: 15px;
  display: block;
  position: relative;
}

div[id*='template-'] {
  width: 70%;
  float: left;
}

.journey-template-preview {
  float: right;
  background: lightgray;
  border-radius: 3px;
  width: 25%;
  top: 20px;
  height: 50px;
  position: relative;
  display: block;
}

#journey-template-sms-input {
  background-color: lightgray;
  border: none;
  color: black;
  height: 50px;
  margin-top: 20px;
  width: 100%;
  border-radius: 3px;
  padding: 0 10px;
}

#journey-template-sms-input::-webkit-input-placeholder {
  color: black;
}

#journey-template-sms-input::placeholder,
#journey-template-sms-input:placeholder-shown {
  color: black;
}

#journey-template-sms-count {
  margin-left: 5px;
}

.plus-button-wrapper button {
  background: transparent;
  border: none;
  color: white;
  cursor: pointer;
}

.journey-notify {
  background: #2ABBB5;
  color: white;
}

.journey-notify-progressbar {
  background: white;
}

.journey-notify-error {
  background: #a41e1e;
  color: white;
}

/* Large devices (desktops, less than 1200px) */

@media (max-width: 1199.98px) {}

/* Medium devices (tablets, less than 992px) */

@media (max-width: 991.98px) {

  /* .journey-builder-graphics-img {
    zoom: 0.5
  } */
  .journey-channel-select-handle {
    font-size: 16px;
  }
}

/* Small devices (landscape phones, less than 768px) */

@media (max-width: 767.98px) {
  .journey {
    padding-bottom: 320px;
  }

  .journey-header {
    font-size: 24px;
  }

  .journey-header div {
    top: 6px;
  }

  .journey-builder-graphics-img {
    /* zoom: 0.5; */
    margin-top: 50px;
    position: relative;
  }

  .journey-channel-select {
    margin: 10px 0;
  }

  .startbar-wrapper {
    position: fixed;
    bottom: 50px;
    z-index: 10;
    height: 140px;
    text-align: center;
  }

  .btn-journey {
    float: unset;
    margin: 20px auto;
  }

  .journey_new_artname {
    font-size: 24px;
    position: relative;
  }

  .plus-button-wrapper {
    font-size: 1.5rem;
  }

  .journey-start-button {
    width: 90px;
  }
}

/* Extra small devices (portrait phones, less than 576px) */

@media (max-width: 575.98px) {}
.delivery {
  background-color: #B30943;
  min-height: 100vh;
  color: white;
}

/** delivery Headers */

.delivery-header {
  background-color: #5B001E;
  height: 80px;
  font-size: 2em;
  position: fixed;
  width: 100%;
  z-index: 10;
}

.delivery-header-text {
  position: relative;
  top: 15px;
  padding-left: 30px;
}

.delivery-header i {
  position: relative;
  top: 15px;
  padding: 0 10px;
}

.delivery-header-bottom {
  background-color: #6F0429;
  width: 100%;
  height: 70px;
  padding: 10px 0 10px 0;
}

.delivery-header-bottom>span {
  position: relative;
  opacity: 0.8;
  padding-right: 20px;
  top: 3px;
}

.delivery-lower-part {
  position: relative;
  top: 80px;
  padding-bottom: 100px;
}

/** delivery Headers */

.overview-box {
  background-color: #BC4D4F;
  margin-bottom: 10px;
  border-radius: 3px 3px 3px 3px;
  text-align: center;
  padding: 10px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.22);
}

.overview-box-middle {
  margin-left: -10px;
  margin-right: -10px;
  background-color: #974542;
  color: #FFF;
  margin-top: 35px;
  padding: 15px 0 15px 0;
}

.overview-box-bottom {
  margin-left: -10px;
  margin-right: -10px;
  background-color: #fff;
  color: #000;
  padding: 15px 0 15px 0;
  border-radius: 0 0 3px 3px;
  margin-bottom: -10px;
}

.tabular-info {
  font-size: 12px;
}

.droid-module.delivery .chart-wrapper,
.droid-module.delivery .overview-box {
  background: #BC4D4F;
}

.droid-module.delivery .chart-wrapper .month-day-filter {
  display: none;
}

.droid-module.delivery .overview-box-middle {
  background: #5B001E;
}

.chart-wrapper-progress-circle-delivery {
  background: none;
  min-height: auto;
}

.chart-wrapper-progress-circle-delivery .CircularProgressbar .CircularProgressbar-trail {
  stroke: #974542;
}

.chart-wrapper-progress-circle-delivery .CircularProgressbar .CircularProgressbar-path {
  stroke: #fff;
}

.chart-wrapper-progress-circle-delivery .CircularProgressbar .CircularProgressbar-text {
  fill: #fff;
}

.chart-wrapper-progress-circle-delivery .progress-circle {
  padding: 0px;
}

@media (max-width: 767.98px) {
  .delivery-header {
    font-size: 24px;
  }
  .delivery-header div {
    top: 6px;
  }
}
.reports {
    background-color: #206B2A;
    min-height: 100vh;
    color: white;
}


/** reports Headers */

.reports-header {
    background-color: #399A44;
    height: 80px;
    font-size: 2em;
    position: fixed;
    width: 100%;
    z-index: 10;
}

.reports-header-text {
    position: relative;
    top: 15px;
    padding-left: 30px;
}

.reports-header i {
    position: relative;
    top: 15px;
    padding: 0 10px;
}

.reports-header-bottom {
    background-color: #0E451F;
    width: 100%;
    height: 70px;
    padding: 10px 0 10px 0;
}

.reports .data-header-bottom {
    background-color: #206B2A;
}

.timespan-datepicker {
    width: 700px;
    float: right;
}

.reports .timespan-datepicker .data-timespan-select__control,
.reports .timespan-datepicker .data-timespan-select__control--is-focused {
    float: left;
    width: 200px;
    top: 8px;
    background: #0E451F;
    color: white;
    border: none !important;
    color: white !important;
    box-shadow: none;
    border-radius: 3px;
}

.reports .data-timespan-select__option--is-selected {
    background: #0E451F !important;
}

.reports .data-timespan-select__menu {
    background: #0E451F !important;
}

.reports .CalendarDay__selected {
    background: #0E451F;
    color: white;
}

.reports .CalendarDay__selected:hover {
    background: #0E451F;
    color: white;
}

.reports .CalendarDay__hovered_span:hover,
.reports .CalendarDay__hovered_span {
    background: #2e8438;
    color: white;
}

.reports .CalendarDay__selected,
.reports .CalendarDay__selected:active,
.reports .CalendarDay__selected:hover {
    border: 1px solid #0E451F;
}

.reports .data-header-bottom .CalendarDay__selected_span {
    background: #2e8438;
}

.reports .audience-delete-btn {
    border-color: #0E451F;
    width: 100%;
    margin: 15px 0 0 0;
}

.reports-notify {
    background: #2e8438;
    color: white;
}

.reports-notify-progressbar {
    background: white;
}

.side-menu-container-reports {
    background-color: #2e8438;
    color: white;
}

.reports-journies-left-side {
    background-color: #2A7E35;
    height: 84vh;
    padding-left: 25px;
    padding-top: 10px;
    position: relative;
    position: fixed;
    width: 25%;
    overflow: hidden;
    overflow-y: scroll;
}

.reports-journies-left-side .journey-type-name {
    cursor: pointer;
}

.reports-journies-left-side-inner {
    transition: all 1s;
}

.reports-journies-left-side ul {
    margin: 0px;
    padding: 10px 20px;
}

.reports-journies-left-side hr {
    padding: 0;
    margin: 0;
    width: 90%;
    right: -15px;
    position: relative;
    border-top: 1px solid rgba(255, 255, 255, .2);
}

.reports-journies-left-side-inner li {
    list-style-type: disc;
}

.reports-journies-left-side .row {
    margin-bottom: 10px;
}

.reports-journies-left-side-inner .single-journey-name {
    color: white;
}

.reports-journies-left-side-inner a {
    text-decoration: none;
}

.reports-header-bottom>span {
    position: relative;
    opacity: 0.8;
    padding-right: 20px;
    top: 3px;
}

.reports-lower-part {
    position: relative;
    top: 80px;
    padding-bottom: 100px;
}


/** reports Headers */

.overview-box {
    background-color: #32893B;
    margin-bottom: 10px;
    border-radius: 3px 3px 3px 3px;
    text-align: center;
    padding: 10px;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.22);
}

.overview-box-middle {
    margin-left: -10px;
    margin-right: -10px;
    background-color: #206929;
    color: #FFF;
    margin-top: 35px;
    padding: 15px 0 15px 0;
}

.overview-box-bottom {
    margin-left: -10px;
    margin-right: -10px;
    background-color: #fff;
    color: #000;
    padding: 15px 0 15px 0;
    border-radius: 0 0 3px 3px;
    margin-bottom: -10px;
}

.tabular-info {
    font-size: 12px;
}

.droid-module.reports .chart-wrapper,
.droid-module.reports .chart-wrapper-top {
    background-color: #32893B;
}

.droid-module.reports .chart-wrapper .month-day-filter {
    display: none;
}

.chart-wrapper-progress-circle-reports {
    background: none;
    min-height: auto;
}

.chart-wrapper-progress-circle-reports .CircularProgressbar .CircularProgressbar-trail {
    stroke: #206929;
}

.chart-wrapper-progress-circle-reports .CircularProgressbar .CircularProgressbar-path {
    stroke: #fff;
}

.chart-wrapper-progress-circle-reports .CircularProgressbar .CircularProgressbar-text {
    fill: #fff;
}

.chart-wrapper-progress-circle-reports .progress-circle {
    padding: 0px;
}

.droid-module.reports .progress-bar {
    background: #B5EA5A;
}

.reports-journey-back-mobile {
    display: none;
}

@media (max-width: 767.98px) {
    .reports-menu-button {
        display: none;
    }
    .reports-header {
        font-size: 24px;
    }
    .reports-header div {
        top: 6px;
    }
    .reports-journies-left-side {
        display: none;
    }
    .reports-journies-right-side h4 {
        text-align: center;
    }
    .reports-journey-back-mobile {
        display: block;
        position: absolute;
        left: 30px;
        font-size: 1.5em;
        top: -4px;
    }
}
.droid-module.connector {
  height: 100vh;
  background: radial-gradient(#385B98, #111D2F);
  background: #243E5C;
  color: white;
}

.item-conector {
  overflow: hidden;
  width: 100%;
  color: white;
  display: flex;
  justify-content: center;
  font-size: 3em;
  line-height: 2em;
  z-index: 10;
}

.item-connector a {
  text-decoration: none;
}

.item-connector i {
  color: #16FFFF;
  font-size: 70px;
  background: #243E5C;
}

.item-connector i::before,
.item-conector i::after {
  background: #243E5C;
}

.item-connector span.uppercase.bold {
  display: table;
  background: #243E5C;
  top: -25px;
}

.connector-line {
  stroke-width: 3px;
  stroke: rgba(22, 255, 255, 1);
}

.connector-lines {
  position: relative;
  left: -74%;
  top: 0px;
  z-index: 0;
}

.item-connector.inactive i {
  color: rgb(22, 125, 136);
}

.item-connector.inactive a {
  cursor: unset;
}

.item-connector.inactive span.uppercase.bold {
  opacity: 0.7;
}

.connector-node #connector-icon-enrichment {
  text-align: center;
}

.connector-icon-center {
  z-index: 10;
}

.droid-module.connector .item-center {
  top: 45%;
  left: 42.5%;
}

.item-website {
  left: 8%;
  top: 27%;
}

.item-geo {
  left: 22%;
  top: 13%;
}

.item-mobile {
  left: 44%;
  top: 12%;
}

.item-messages {
  left: 67%;
  top: 13%;
}

.item-newsletter {
  left: 80%;
  top: 27%;
}

.item-retargeting {
  left: 84%;
  top: 45%;
  z-index: 30 !important;
}

.item-products {
  left: 75%;
  top: 61%;
  z-index: 40 !important;
}

.item-social {
  left: 55%;
  top: 70%;
  z-index: 40 !important;
}

.item-enrichment {
  left: 30%;
  top: 70%;
  z-index: 40 !important;
}

.item-order {
  left: 10%;
  top: 63%;
  z-index: 30 !important;
}

.item-crm {
  left: 5%;
  top: 45%;
}

/** Connector Timeline **/

.connector-timeline {
  border-right: 1px solid #16FFFF;
  height: 100vh;
}

.connector-timeline-header {
  text-align: center;
  padding: 40px 0;
}

.connector-timeline-header svg {
  position: relative;
  height: unset;
  width: unset;
}

.connector-timeline-header i {
  float: right;
  padding-right: 30px;
  cursor: pointer;
}

.single-timeline {
  color: #16FFFF;
  font-size: 0.8em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 5px;
}

.single-timeline span::after {
  content: ' | ';
}

.single-timeline span:last-of-type::after {
  content: '';
}

.single-timeline span:last-of-type::after,
.single-timeline span.url::after {
  content: '';
}

.add-interface-wrapper {
  height: 460px;
  width: 80vw;
  max-width: 1000px;
  background: white;
  border-radius: 3px;
  text-align: center;
  position: relative;
}

.add-interface-wrapper button {
  margin: 0px 40px;
  background: #243E5C;
  color: white;
  border: none;
  border-radius: 3px;
  padding: 5px;
  margin-bottom: 15px;
}

.add-interface-wrapper .add-interface-button-close {
  background: darkgray;
  color: black;
}

.add-interface-header {
  background: #16FFFF;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  padding: 20px 0;
}

.add-interface-body-channel,
.add-interface-body-arrow {
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.add-interface-body i {
  font-size: 6em;
  font-weight: 600;
}

.add-interface-body hr {
  border: 1px solid black;
  width: 95%;
  margin: 10px auto;
}

.api-credentials-input {
  margin: 20px 0;
}

.add-interface-body label {
  float: right;
}

.add-interface-body input {
  float: left;
}

.add-interface-img-wrapper {
  width: 200px;
  height: 200px;
  margin: 0 auto;
}

.add-interface-img {
  max-width: 200px;
  max-height: 200px;
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

#interface-select {
  position: relative;
  display: block;
  margin: 0 auto;
  width: 80%;
}

.add-interface-footer {
  position: absolute;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

@media (max-width: 767.98px) {
  .inner-connector-spider {
    display: none;
  }

  .connector-timeline {
    border: none;
  }
}

@media (max-height: 640px) {
  .connector-timeline-header i {
    display: none;
  }
}
/* Nav_style */

#desktop_nav {
  display: block;
  background-color: #243E5C;
  bottom: 0;
  width: 100%;
  z-index: 100;
}

#desktop_nav ul {
  flex-direction: row;
  float: left;
}

#desktop_nav ul li {
  display: inline;
}

#desktop_nav .settings {
  float: right;
  color: white;
  font-size: 2em;
  padding-top: 10px;
  cursor: pointer;
}

.spinning-cogs-wrapper {
  width: 50px;
  height: 50px;
  position: relative;
}

#gear1,
#gear2,
#gear3 {
  color: #888;
  display: block;
  float: right;
  position: relative;
}

#gear1 {
  /* top: 45px; */
}

#gear2 {
  left: 15px;
  top: -5px;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}

#gear3 {
  left: 15px;
  top: 7px;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}

.spin {
  -webkit-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

.spin-back {
  -webkit-animation: spin-back 4s linear infinite;
  animation: spin-back 4s linear infinite;
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin-back {
  100% {
    -webkit-transform: rotate(-360deg);
  }
}

@keyframes spin-back {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

#droid-logo-navbar {
  width: 210px;
}

.DayPickerKeyboardShortcuts_buttonReset {
  display: none;
}

.nav-header {
  z-index: 2;
  top: 0;
}

.bubbles {
  transition: all 0.2s ease;
  right: 0;
}

.uppercase.bold {
  position: relative;
  font-size: 16px;
  font-weight: 600;
  bottom: 5px;
  color: white;
}

.navbar {
  background-color: #14233D;
  position: fixed;
  min-height: 50px;
  margin-bottom: 0px;
  border: 1px solid transparent;
}

.navbar-nav {
  margin-left: 0px;
  margin-top: 10px;
}

/* Bubble_style */

.nav>li>a.nav_data:hover,
.nav>li>a.nav_data:focus {
  background-color: #207EC3;
}

.nav>li>a.nav_evaluation:hover,
.nav>li>a.nav_evaluation:focus {
  background-color: #9C2B97;
}

.nav>li>a.nav_journey:hover,
.nav>li>a.nav_journey:focus {
  background-color: #20A9A0;
}

.nav>li>a.nav_ai:hover,
.nav>li>a.nav_ai:focus {
  background-color: #F3922F;
}

.nav>li>a.nav_creator:hover,
.nav>li>a.nav_creator:focus {
  background-color: #D4DEE9;
}

.nav>li>a.nav_creator:hover span,
.nav>li>a.nav_creator:focus span {
  color: black;
}

.nav>li>a.nav_deliver:hover,
.nav>li>a.nav_deliver:focus {
  background-color: #B00C44;
}

.nav>li>a.nav_controlling:hover,
.nav>li>a.nav_controlling:focus {
  background-color: #399A44;
}

.bubble {
  display: inline-block;
  border-radius: 7.0em;
  padding: 10px 20px;
  font-size: 18px;
  font-family: 'Source Sans Pro', sans-serif;
  height: 45px;
  margin: 5px 10px;
  background: #243E5C;
}

.bubble.inactive {
  opacity: 0.3;
  padding: 12px 30px 10px 25px;
}

.bubble.inactive span {
  opacity: 0.3;
}

.bubble.inactive .uppercase {
  position: relative;
  display: block
}

.bubble:focus,
.bubble:hover {
  color: #fff;
  text-decoration: none;
}

.bubble.data-connector-bubble {
  border: 3px solid #077DC5;
  position: relative;
  top: 0%;
  left: 0%;
  padding-top: 10px;
}

.bubble.evaluation-connector-bubble {
  border: 3px solid #9E2799;
  position: relative;
  top: 0%;
  left: 0%;
  padding-top: 10px;
}

.bubble.journey-connector-bubble {
  border: 3px solid #04A9A0;
  position: relative;
  top: 0%;
  left: 0%;
  padding-top: 10px;
}

.bubble.creator-connector-bubble {
  border: 3px solid #D4DEE9;
  position: relative;
  top: 0%;
  left: 0%;
  padding-top: 10px;
}

.bubble.ai-connector-bubble {
  border: 3px solid #F6931D;
  position: relative;
  top: 0%;
  left: 0%;
}

.bubble.controlling-connector-bubble {
  border: 3px solid #349B40;
  position: relative;
  top: 0%;
  left: 0%;
  right: 0%;
}

.bubble.delivering-connector-bubble {
  border: 3px solid #B30943;
  position: relative;
  top: 0%;
  right: 0%;
  left: 0%;
}

.bubble.inactive .uppercase::after {
  top: 0px !important;
}

.nav>li>a.nav_creator:hover,
.nav>li>a.nav_creator:focus {
  color: black;
}

#desktop_nav .nav_data.active {
  background-color: #207EC3;
}

#desktop_nav .nav_evaluation.active {
  background-color: #9C2B97;
}

#desktop_nav .nav_journey.active {
  background-color: #20A9A0;
}

#desktop_nav .nav_deliver.active {
  background-color: #B00C44;
}

#desktop_nav .nav_controlling.active {
  background-color: #399A44;
}

.navbar-switch {
  display: none;
  color: white;
  font-size: 1.5em;
}

.mobile-home {
  position: absolute;
  bottom: 0;
  right: 70px;
}

.mobile-home img {
  height: 55px;
}

@media (max-width: 767.98px) {
  .navbar-switch {
    display: block;
  }
  .bubble .uppercase.bold {
    font-size: 14px;
    font-weight: 600;
  }
}

@media (max-width: 1010px) {
  #droid-logo-navbar {
    display: none;
  }
}

@media (min-width: 1010px) {
  .mobile-home {
    display: none;
  }
}
