.audience {
  background: #6d1b71;
  background: -moz-linear-gradient(top, #6d1b71 0%, #8f278c 100%);
  background: -webkit-linear-gradient(top, #6d1b71 0%, #8f278c 100%);
  background: linear-gradient(to bottom, #6d1b71 0%, #8f278c 100%);
  min-height: 100vh;
  padding-bottom: 120px;
}

.audience-notify {
  background: #6d1b71;
  color: white;
}

.audience-notify-progressbar {
  background: white;
}

.rc-switch {
  transform: rotate(90deg) scale(0.8);
}

.audience-header {
  background-color: #6d1b71;
  color: white;
  position: fixed;
  width: 100%;
  z-index: 12;
}

.audience-top-header {
  height: 80px;
  line-height: 0.7;
  font-size: 2em;
  padding-top: 30px;
}

.audience-top-header-icon-wrapper {
  text-align: right;
}

.audience-top-header-icon-wrapper .info-text-wrapper {
  display: inline;
}

.audience-top-header-icon-wrapper i {
  top: -7px;
  position: relative;
  padding: 0 10px;
  cursor: pointer;
}

.audience-top-header-text {
  margin: 0 30px;
  font-size: 32px;
}

.audience-bottom-header {
  height: 70px;
  background-color: #4f0d51;
}

.audience-save-btn {
  position: relative;
  color: white;
  height: 30px;
  width: 250px;
  margin: 22px 15px;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  font-size: 16px;
  font-weight: 500;
  border-color: #8E678F;
  background-color: transparent;
  text-align: center;
  cursor: pointer;
}

.audience-delete-btn {
  position: relative;
  color: white;
  height: 30px;
  width: 250px;
  margin: 22px 15px;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  font-size: 16px;
  font-weight: 500;
  border-color: #8E678F;
  background-color: transparent;
  text-align: center;
  cursor: pointer;
}

.audience-save-btn:focus,
.audience-delete-btn:focus {
  outline: none;
}

.audience-save-btn span,
.audience-delete-btn span {
  float: left;
}

.audience-save-btn svg,
.audience-delete-btn svg {
  float: right;
  position: relative;
  top: 4px;
}

.audience-body {
  margin: 0 30px;
  padding-top: 160px;
}

.audience-count-switch {
  width: 100%;
  max-width: 20vw;
  margin: 10px 0;
  padding: 0 10px;
  color: white;
  background-color: #732677;
  border-radius: 3px;
  border-style: solid;
  border-width: 2px;
  border-color: #873488;
  overflow: hidden;
  height: 105px;
  transition-duration: 0.3s;
  font-size: 0.8rem;
  float: right;
  position: fixed;
  right: 30px;
  top: 140px;
  z-index: 10;
}

.audience-count {
  margin: 7px 0;
}

.audience-name-input {
  margin-top: 10px;
  width: 85vw;
  float: left;
}

.audience-name-input input:focus {
  outline: none;
}

.audience-name-input label,
.audience-name-input input,
.audience-name-input input::placeholder,
.side-menu-search input,
.side-menu-search input::placeholder {
  color: #BA8CBA;
}

.audience-name-input input {
  display: block;
  width: 100%;
  height: 34px;
  font-size: 16px;
  background-color: transparent;
  box-shadow: none;
  border-radius: 0px;
  border: 0px solid #BA8CBA;
  border-bottom: 1px solid
}

.audience-count span.count {
  font-size: 24px;
}

.audience-count-switch .count-wrapper {
  margin-top: 25px;
}

.col.enrichment,
.col.audience-switch-anonym {
  display: none;
}

/* CARDS STYLE */

.audience .card-body {
  background-color: rgb(125, 56, 131);
  color: white;
  margin: 5px 15px;
  padding: 0;
  padding-bottom: 5px;
  border-radius: 3px;
}

.audience .card-head {
  text-align: center;
  background-color: #8F3F90;
  margin-bottom: 10px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.card-inner-body .input-group-prepend {
  cursor: pointer;
}

.audience-slider .rc-slider-mark-text {
  color: white;
}

.audience-slider .rc-slider-track {
  background-color: #A858A6;
}

.audience-slider .rc-slider-dot-active {
  background-color: #A858A6;
  border-color: #A858A6;
}

.audience-slider .rc-slider-handle,
.audience-slider .rc-slider-handle-focused {
  border-color: white;
}

.card-body span.form-control {
  height: 100%;
}

div[data-react-beautiful-dnd-droppable] {
  margin: 10px;
  min-width: 280px;
  height: 100%;
}

.single-selection .input-group-text {
  background-color: transparent;
  border: none;
}

.single-selection.disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.5;
}

label {
  display: inherit;
  margin: 0;
}

.selection-name {
  margin: 0 10px;
}

.audience-selection-input {
  position: relative;
  margin-top: 30px;
  margin-left: -120px;
}

.little-loading-droid {
  display: none;
  height: 40px;
  top: 10px;
  position: relative;
  margin: -14px auto;
  content: url('../../pictures/loading-droid.gif');
}

#audience-postcode-input {
  font-size: 15px;
  font-weight: 500;
  margin-left: 10px;
  width: 100px;
}

/* Checkbox Audience Stye */

.rc-checkbox-inner {
  background-color: #7d3883;
}

.rc-checkbox:hover .rc-checkbox-inner,
.rc-checkbox-input:focus+.rc-checkbox-inner {
  border-color: white;
}

.rc-checkbox-checked:hover .rc-checkbox-inner {
  border-color: white;
}

.rc-checkbox-checked .rc-checkbox-inner {
  border-color: white;
  background-color: #7d3883;
}

.rc-checkbox-inner:after {
  content: none;
}

.rc-checkbox-disabled.rc-checkbox-checked:hover .rc-checkbox-inner,
.rc-checkbox-disabled:hover .rc-checkbox-inner,
.rc-checkbox-disabled .rc-checkbox-inner:after,
.rc-checkbox-disabled.rc-checkbox-checked .rc-checkbox-inner:after {
  border-color: white;
  opacity: 0.1;
}

.rc-checkbox-disabled.rc-checkbox-checked .rc-checkbox-inner,
.rc-checkbox-disabled .rc-checkbox-inner {
  background-color: #7d3883;
  border-color: white;
  opacity: 0.1;
}

.rc-checkbox-disabled,
.rc-checkbox-disabled .rc-checkbox-inner-input,
.rc-checkbox-disabled .rc-checkbox-input {
  cursor: not-allowed;
}

/* Switch Audience Style */

.rc-switch,
.rc-switch-checked {
  border: 1px solid white;
  background-color: #7d3883;
}

.side-menu-logo img {
  height: 45px;
}

.side-menu-logo p {
  margin: 18px;
}

.side-menu-logo span {
  font-family: Raleway, sans-serif;
  color: white;
  letter-spacing: 3px;
}

.side-menu-logo span:nth-of-type(1) {
  font-size: 12pt;
  font-weight: 600;
}

.side-menu-logo span:nth-of-type(2) {
  font-size: 13pt;
  font-weight: 300;
}

.side-menu-container .droid-btn {
  margin: 25px auto;
  height: 40px;
  padding: 10px 10px;
  font-size: 10pt;
}

.side-menu-search {
  padding: 30px 30px;
  min-height: 130px;
  border-top: 1px solid #BA8CBA;
  border-bottom: 1px solid #BA8CBA;
  max-height: 55vh;
  overflow: hidden;
  overflow-y: scroll;
}

.side-menu-search input {
  padding-bottom: 3px;
  font-size: 13pt;
  font-weight: 300;
  border: none;
  width: 300px;
  background: rgba(0, 0, 0, 0);
  outline: none;
  margin-top: 5px;
  color: #8E678F;
}

.side-menu-search .input {
  border-bottom: 1px solid #7d3883;
}

.side-menu-container .title {
  font-size: 10pt;
  color: white;
}

.side-menu-search {
  color: #8E678F;
}

.search-result {
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 20px;
  color: #8E678F;
  list-style: none;
}

.search-result .no-padder {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.search-result div {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.side-menu-container li {
  color: #BA8CBA;
  padding: 5px 0;
  border-bottom: 1px solid #7d3883;
}

.no-padder {
  padding: 0px;
}

.side-menu-container .icon-color {
  color: #BA8CBA;
}

.side-menu-container ul .icon-color {
  color: white;
}

.side-menu-target-list {
  padding: 15px 20px 0px 20px;
  height: 48vh;
  overflow: hidden;
  overflow-y: scroll;
}

.audience-menu-inner li {
  cursor: pointer;
}

.side-menu-container .target-list {
  width: 320px;
  padding: 0 15px;
}

#saveTargetBtn {
  cursor: pointer;
}

.fade-enter {
  opacity: 0.01;
  width: 0;
}

.fade-enter-done {
  opacity: 1;
  transition: width 0.5s ease;
  width: 380px;
}

.fade-exit {
  opacity: 1;
  transition: width 0.5s ease;
  width: 0;
}

.fade-exit-done {
  opacity: 1;
  transition: width 0.5s ease;
  width: 0;
  padding: 0;
}

.fade-exit-done .row {
  display: none;
}

.fade-exit-done .row {
  padding: 0;
}

/* Large devices (desktops, less than 1200px) */

@media (max-width: 1199.98px) {}

/* Medium devices (tablets, less than 992px) */

@media (max-width: 991.98px) {}

/* Small devices (landscape phones, less than 768px) */

@media (max-width: 767.98px) {
  .audience {
    padding-bottom: 200px;
  }

  .audience-count-switch {
    width: 80%;
    height: 80px;
    margin: 0 auto;
    position: fixed;
    bottom: 60px;
    z-index: 10;
    max-width: 800px;
    left: 5px;
    right: 0;
    top: unset;
  }

  .audience-count-switch .audience-count {
    margin: -15px 0;
  }

  .audience-top-header-text {
    font-size: 24px;
  }

  .audience-top-header {
    font-size: 24px;
    top: 3px;
    position: relative;
  }

  .audience-top-header .col-8 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .audience-bottom-header {
    text-align: center;
  }

  div[data-react-beautiful-dnd-droppable] {
    min-width: 95%;
  }

  .card-body {
    margin: 0 !important;
  }

  .side-menu-container.fade-enter-done {
    width: 380px;
    z-index: 90;
  }
}

/* Extra small devices (portrait phones, less than 576px) */

@media (max-width: 575.98px) {}