html,
body {
  max-height: 100%;
}

.droid-module.main {
  height: 100vh;
  background-color: #243E5C;
  color: white;
}

.inactive {
  opacity: 0.5;
}

.bold {
  font-weight: 600;
}

.semi-bold {
  font-weight: 400;
}

.italic {
  font-style: italic;
}

.thin {
  font-weight: 100;
}

.large {
  font-size: xx-large;
}

.chart-wrapper-bottom .single-bottom-text .large {
  font-size: 2.4em;
}

@media (max-width: 767.98px) {
  .info-text-wrapper {
    display: none !important;
  }

  .navbar-li-creator {
    display: none !important;
  }
}