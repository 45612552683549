.reports {
    background-color: #206B2A;
    min-height: 100vh;
    color: white;
}


/** reports Headers */

.reports-header {
    background-color: #399A44;
    height: 80px;
    font-size: 2em;
    position: fixed;
    width: 100%;
    z-index: 10;
}

.reports-header-text {
    position: relative;
    top: 15px;
    padding-left: 30px;
}

.reports-header i {
    position: relative;
    top: 15px;
    padding: 0 10px;
}

.reports-header-bottom {
    background-color: #0E451F;
    width: 100%;
    height: 70px;
    padding: 10px 0 10px 0;
}

.reports .data-header-bottom {
    background-color: #206B2A;
}

.timespan-datepicker {
    width: 700px;
    float: right;
}

.reports .timespan-datepicker .data-timespan-select__control,
.reports .timespan-datepicker .data-timespan-select__control--is-focused {
    float: left;
    width: 200px;
    top: 8px;
    background: #0E451F;
    color: white;
    border: none !important;
    color: white !important;
    box-shadow: none;
    border-radius: 3px;
}

.reports .data-timespan-select__option--is-selected {
    background: #0E451F !important;
}

.reports .data-timespan-select__menu {
    background: #0E451F !important;
}

.reports .CalendarDay__selected {
    background: #0E451F;
    color: white;
}

.reports .CalendarDay__selected:hover {
    background: #0E451F;
    color: white;
}

.reports .CalendarDay__hovered_span:hover,
.reports .CalendarDay__hovered_span {
    background: #2e8438;
    color: white;
}

.reports .CalendarDay__selected,
.reports .CalendarDay__selected:active,
.reports .CalendarDay__selected:hover {
    border: 1px solid #0E451F;
}

.reports .data-header-bottom .CalendarDay__selected_span {
    background: #2e8438;
}

.reports .audience-delete-btn {
    border-color: #0E451F;
    width: 100%;
    margin: 15px 0 0 0;
}

.reports-notify {
    background: #2e8438;
    color: white;
}

.reports-notify-progressbar {
    background: white;
}

.side-menu-container-reports {
    background-color: #2e8438;
    color: white;
}

.reports-journies-left-side {
    background-color: #2A7E35;
    height: 84vh;
    padding-left: 25px;
    padding-top: 10px;
    position: relative;
    position: fixed;
    width: 25%;
    overflow: hidden;
    overflow-y: scroll;
}

.reports-journies-left-side .journey-type-name {
    cursor: pointer;
}

.reports-journies-left-side-inner {
    transition: all 1s;
}

.reports-journies-left-side ul {
    margin: 0px;
    padding: 10px 20px;
}

.reports-journies-left-side hr {
    padding: 0;
    margin: 0;
    width: 90%;
    right: -15px;
    position: relative;
    border-top: 1px solid rgba(255, 255, 255, .2);
}

.reports-journies-left-side-inner li {
    list-style-type: disc;
}

.reports-journies-left-side .row {
    margin-bottom: 10px;
}

.reports-journies-left-side-inner .single-journey-name {
    color: white;
}

.reports-journies-left-side-inner a {
    text-decoration: none;
}

.reports-header-bottom>span {
    position: relative;
    opacity: 0.8;
    padding-right: 20px;
    top: 3px;
}

.reports-lower-part {
    position: relative;
    top: 80px;
    padding-bottom: 100px;
}


/** reports Headers */

.overview-box {
    background-color: #32893B;
    margin-bottom: 10px;
    border-radius: 3px 3px 3px 3px;
    text-align: center;
    padding: 10px;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.22);
}

.overview-box-middle {
    margin-left: -10px;
    margin-right: -10px;
    background-color: #206929;
    color: #FFF;
    margin-top: 35px;
    padding: 15px 0 15px 0;
}

.overview-box-bottom {
    margin-left: -10px;
    margin-right: -10px;
    background-color: #fff;
    color: #000;
    padding: 15px 0 15px 0;
    border-radius: 0 0 3px 3px;
    margin-bottom: -10px;
}

.tabular-info {
    font-size: 12px;
}

.droid-module.reports .chart-wrapper,
.droid-module.reports .chart-wrapper-top {
    background-color: #32893B;
}

.droid-module.reports .chart-wrapper .month-day-filter {
    display: none;
}

.chart-wrapper-progress-circle-reports {
    background: none;
    min-height: auto;
}

.chart-wrapper-progress-circle-reports .CircularProgressbar .CircularProgressbar-trail {
    stroke: #206929;
}

.chart-wrapper-progress-circle-reports .CircularProgressbar .CircularProgressbar-path {
    stroke: #fff;
}

.chart-wrapper-progress-circle-reports .CircularProgressbar .CircularProgressbar-text {
    fill: #fff;
}

.chart-wrapper-progress-circle-reports .progress-circle {
    padding: 0px;
}

.droid-module.reports .progress-bar {
    background: #B5EA5A;
}

.reports-journey-back-mobile {
    display: none;
}

@media (max-width: 767.98px) {
    .reports-menu-button {
        display: none;
    }
    .reports-header {
        font-size: 24px;
    }
    .reports-header div {
        top: 6px;
    }
    .reports-journies-left-side {
        display: none;
    }
    .reports-journies-right-side h4 {
        text-align: center;
    }
    .reports-journey-back-mobile {
        display: block;
        position: absolute;
        left: 30px;
        font-size: 1.5em;
        top: -4px;
    }
}