/* Nav_style */

#desktop_nav {
  display: block;
  background-color: #243E5C;
  bottom: 0;
  width: 100%;
  z-index: 100;
}

#desktop_nav ul {
  flex-direction: row;
  float: left;
}

#desktop_nav ul li {
  display: inline;
}

#desktop_nav .settings {
  float: right;
  color: white;
  font-size: 2em;
  padding-top: 10px;
  cursor: pointer;
}

.spinning-cogs-wrapper {
  width: 50px;
  height: 50px;
  position: relative;
}

#gear1,
#gear2,
#gear3 {
  color: #888;
  display: block;
  float: right;
  position: relative;
}

#gear1 {
  /* top: 45px; */
}

#gear2 {
  left: 15px;
  top: -5px;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}

#gear3 {
  left: 15px;
  top: 7px;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}

.spin {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

.spin-back {
  -webkit-animation: spin-back 4s linear infinite;
  -moz-animation: spin-back 4s linear infinite;
  animation: spin-back 4s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spin-back {
  100% {
    -moz-transform: rotate(-360deg);
  }
}

@-webkit-keyframes spin-back {
  100% {
    -webkit-transform: rotate(-360deg);
  }
}

@keyframes spin-back {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

#droid-logo-navbar {
  width: 210px;
}

.DayPickerKeyboardShortcuts_buttonReset {
  display: none;
}

.nav-header {
  z-index: 2;
  top: 0;
}

.bubbles {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  right: 0;
}

.uppercase.bold {
  position: relative;
  font-size: 16px;
  font-weight: 600;
  bottom: 5px;
  color: white;
}

.navbar {
  background-color: #14233D;
  position: fixed;
  min-height: 50px;
  margin-bottom: 0px;
  border: 1px solid transparent;
}

.navbar-nav {
  margin-left: 0px;
  margin-top: 10px;
}

/* Bubble_style */

.nav>li>a.nav_data:hover,
.nav>li>a.nav_data:focus {
  background-color: #207EC3;
}

.nav>li>a.nav_evaluation:hover,
.nav>li>a.nav_evaluation:focus {
  background-color: #9C2B97;
}

.nav>li>a.nav_journey:hover,
.nav>li>a.nav_journey:focus {
  background-color: #20A9A0;
}

.nav>li>a.nav_ai:hover,
.nav>li>a.nav_ai:focus {
  background-color: #F3922F;
}

.nav>li>a.nav_creator:hover,
.nav>li>a.nav_creator:focus {
  background-color: #D4DEE9;
}

.nav>li>a.nav_creator:hover span,
.nav>li>a.nav_creator:focus span {
  color: black;
}

.nav>li>a.nav_deliver:hover,
.nav>li>a.nav_deliver:focus {
  background-color: #B00C44;
}

.nav>li>a.nav_controlling:hover,
.nav>li>a.nav_controlling:focus {
  background-color: #399A44;
}

.bubble {
  display: inline-block;
  border-radius: 7.0em;
  padding: 10px 20px;
  font-size: 18px;
  font-family: 'Source Sans Pro', sans-serif;
  height: 45px;
  margin: 5px 10px;
  background: #243E5C;
}

.bubble.inactive {
  opacity: 0.3;
  padding: 12px 30px 10px 25px;
}

.bubble.inactive span {
  opacity: 0.3;
}

.bubble.inactive .uppercase {
  position: relative;
  display: block
}

.bubble:focus,
.bubble:hover {
  color: #fff;
  text-decoration: none;
}

.bubble.data-connector-bubble {
  border: 3px solid #077DC5;
  position: relative;
  top: 0%;
  left: 0%;
  padding-top: 10px;
}

.bubble.evaluation-connector-bubble {
  border: 3px solid #9E2799;
  position: relative;
  top: 0%;
  left: 0%;
  padding-top: 10px;
}

.bubble.journey-connector-bubble {
  border: 3px solid #04A9A0;
  position: relative;
  top: 0%;
  left: 0%;
  padding-top: 10px;
}

.bubble.creator-connector-bubble {
  border: 3px solid #D4DEE9;
  position: relative;
  top: 0%;
  left: 0%;
  padding-top: 10px;
}

.bubble.ai-connector-bubble {
  border: 3px solid #F6931D;
  position: relative;
  top: 0%;
  left: 0%;
}

.bubble.controlling-connector-bubble {
  border: 3px solid #349B40;
  position: relative;
  top: 0%;
  left: 0%;
  right: 0%;
}

.bubble.delivering-connector-bubble {
  border: 3px solid #B30943;
  position: relative;
  top: 0%;
  right: 0%;
  left: 0%;
}

.bubble.inactive .uppercase::after {
  top: 0px !important;
}

.nav>li>a.nav_creator:hover,
.nav>li>a.nav_creator:focus {
  color: black;
}

#desktop_nav .nav_data.active {
  background-color: #207EC3;
}

#desktop_nav .nav_evaluation.active {
  background-color: #9C2B97;
}

#desktop_nav .nav_journey.active {
  background-color: #20A9A0;
}

#desktop_nav .nav_deliver.active {
  background-color: #B00C44;
}

#desktop_nav .nav_controlling.active {
  background-color: #399A44;
}

.navbar-switch {
  display: none;
  color: white;
  font-size: 1.5em;
}

.mobile-home {
  position: absolute;
  bottom: 0;
  right: 70px;
}

.mobile-home img {
  height: 55px;
}

@media (max-width: 767.98px) {
  .navbar-switch {
    display: block;
  }
  .bubble .uppercase.bold {
    font-size: 14px;
    font-weight: 600;
  }
}

@media (max-width: 1010px) {
  #droid-logo-navbar {
    display: none;
  }
}

@media (min-width: 1010px) {
  .mobile-home {
    display: none;
  }
}