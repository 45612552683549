/* Global CSS File */

/* @font-face {
  font-family: Source Sans Pro;
  src: url(Source_Sans_Pro/SourceSansPro-Regular.ttf);
} */

@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i');

body {
  margin: 0;
  padding: 0;
  font-family: 'Source Sans Pro', sans-serif !important;
}

.shadow {
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.3) !important;
}

#root {
  overflow: hidden;
}

.droid-module {
  /* because of Navbar */
  padding-bottom: 100px;
}

/** RC SWITCH CSS **/

.rc-switch {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 44px;
  height: 22px;
  line-height: 20px;
  vertical-align: middle;
  border-radius: 20px 20px;
  border: 1px solid #ccc;
  background-color: #ccc;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}

.rc-switch-inner {
  color: #fff;
  font-size: 12px;
  position: absolute;
  left: 24px;
}

.rc-switch:after {
  position: absolute;
  width: 18px;
  height: 18px;
  left: 2px;
  top: 1px;
  border-radius: 50% 50%;
  background-color: #fff;
  content: " ";
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
  transform: scale(1);
  transition: left 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
  animation-duration: 0.3s;
  animation-name: rcSwitchOff;
}

.rc-switch:hover:after {
  transform: scale(1.1);
  animation-name: rcSwitchOn;
}

.rc-switch:focus {
  box-shadow: 0 0 0 2px #d5f1fd;
  outline: none;
}

.rc-switch-checked {
  border: 1px solid #87d068;
  background-color: #87d068;
}

.rc-switch-checked .rc-switch-inner {
  left: 6px;
}

.rc-switch-checked:after {
  left: 22px;
}

.rc-switch-disabled {
  cursor: no-drop;
  background: #ccc;
  border-color: #ccc;
}

.rc-switch-disabled:after {
  background: #9e9e9e;
  animation-name: none;
  cursor: no-drop;
}

.rc-switch-disabled:hover:after {
  transform: scale(1);
  animation-name: none;
}

.rc-switch-label {
  display: inline-block;
  line-height: 20px;
  font-size: 14px;
  padding-left: 10px;
  vertical-align: middle;
  white-space: normal;
  pointer-events: none;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

@keyframes rcSwitchOn {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.25);
  }

  100% {
    transform: scale(1.1);
  }
}

@keyframes rcSwitchOff {
  0% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

/** RC-Checkbox CSS **/

/* 一般状态 */

.rc-checkbox {
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  display: inline-block;
  position: relative;
  line-height: 1;
  vertical-align: middle;
}

.rc-checkbox:hover .rc-checkbox-inner,
.rc-checkbox-input:focus+.rc-checkbox-inner {
  border-color: #3dbcf6;
}

.rc-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: inline-block;
  width: 14px;
  height: 14px;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  border-color: #d9d9d9;
  background-color: #ffffff;
  /* transition: border-color 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55), background-color 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55); */
}

.rc-checkbox-inner:after {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  left: 4px;
  top: 1px;
  display: table;
  width: 5px;
  height: 8px;
  border: 2px solid #ffffff;
  border-top: 0;
  border-left: 0;
  content: ' ';
  /* animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55); */
  /* animation-duration: 0.3s; */
  /* animation-name: amCheckboxOut; */
}

.rc-checkbox-input {
  position: absolute;
  left: 0;
  z-index: 9999;
  cursor: pointer;
  opacity: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.rc-checkbox-checked:hover .rc-checkbox-inner {
  border-color: #3dbcf6;
}

.rc-checkbox-checked .rc-checkbox-inner {
  border-color: #3dbcf6;
  background-color: #3dbcf6;
}

.rc-checkbox-checked .rc-checkbox-inner:after {
  transform: rotate(45deg);
  position: absolute;
  left: 4px;
  top: 1px;
  display: table;
  width: 5px;
  height: 8px;
  border: 2px solid #ffffff;
  border-top: 0;
  border-left: 0;
  content: ' ';
  /* animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55); */
  /* animation-duration: 0.3s; */
  /* animation-name: amCheckboxOut; */
}

.rc-checkbox-disabled.rc-checkbox-checked:hover .rc-checkbox-inner {
  border-color: #d9d9d9;
}

.rc-checkbox-disabled.rc-checkbox-checked .rc-checkbox-inner {
  background-color: #f3f3f3;
  border-color: #d9d9d9;
}

.rc-checkbox-disabled.rc-checkbox-checked .rc-checkbox-inner:after {
  animation-name: none;
  border-color: #cccccc;
}

.rc-checkbox-disabled:hover .rc-checkbox-inner {
  border-color: #d9d9d9;
}

.rc-checkbox-disabled .rc-checkbox-inner {
  border-color: #d9d9d9;
  background-color: #f3f3f3;
}

.rc-checkbox-disabled .rc-checkbox-inner:after {
  animation-name: none;
  border-color: #f3f3f3;
}

.rc-checkbox-disabled .rc-checkbox-inner-input {
  cursor: default;
}

@keyframes amCheckboxIn {
  0% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(0, 0) rotate(45deg);
  }

  100% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1) rotate(45deg);
  }
}

@keyframes amCheckboxOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/** RC-Slider CSS **/

.rc-slider {
  position: relative;
  height: 14px;
  padding: 5px 0;
  width: 100%;
  border-radius: 6px;
  -ms-touch-action: none;
  touch-action: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider-rail {
  position: absolute;
  width: 100%;
  background-color: #e9e9e9;
  height: 4px;
  border-radius: 6px;
}

.rc-slider-track {
  position: absolute;
  left: 0;
  height: 4px;
  border-radius: 6px;
  background-color: #abe2fb;
}

.rc-slider-handle {
  position: absolute;
  margin-left: -7px;
  margin-top: -5px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  cursor: -webkit-grab;
  cursor: grab;
  border-radius: 50%;
  border: solid 2px #96dbfa;
  background-color: #fff;
  -ms-touch-action: pan-x;
  touch-action: pan-x;
}

.rc-slider-handle:focus {
  border-color: #57c5f7;
  box-shadow: 0 0 0 5px #96dbfa;
  outline: none;
}

.rc-slider-handle-click-focused:focus {
  border-color: #96dbfa;
  box-shadow: unset;
}

.rc-slider-handle:hover {
  border-color: #57c5f7;
}

.rc-slider-handle:active {
  border-color: #57c5f7;
  box-shadow: 0 0 5px #57c5f7;
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.rc-slider-mark {
  position: absolute;
  top: 18px;
  left: 0;
  width: 100%;
  font-size: 12px;
}

.rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #999;
}

.rc-slider-mark-text-active {
  color: #666;
}

.rc-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
}

.rc-slider-dot {
  position: absolute;
  bottom: -2px;
  margin-left: -4px;
  width: 8px;
  height: 8px;
  border: 2px solid #e9e9e9;
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle;
}

.rc-slider-dot-active {
  border-color: #96dbfa;
}

.rc-slider-disabled {
  background-color: #e9e9e9;
}

.rc-slider-disabled .rc-slider-track {
  background-color: #ccc;
}

.rc-slider-disabled .rc-slider-handle,
.rc-slider-disabled .rc-slider-dot {
  border-color: #ccc;
  box-shadow: none;
  background-color: #fff;
  cursor: not-allowed;
}

.rc-slider-disabled .rc-slider-mark-text,
.rc-slider-disabled .rc-slider-dot {
  cursor: not-allowed !important;
}

.rc-slider-vertical {
  width: 14px;
  height: 100%;
  padding: 0 5px;
}

.rc-slider-vertical .rc-slider-rail {
  height: 100%;
  width: 4px;
}

.rc-slider-vertical .rc-slider-track {
  left: 5px;
  bottom: 0;
  width: 4px;
}

.rc-slider-vertical .rc-slider-handle {
  margin-left: -5px;
  margin-bottom: -7px;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.rc-slider-vertical .rc-slider-mark {
  top: 0;
  left: 18px;
  height: 100%;
}

.rc-slider-vertical .rc-slider-step {
  height: 100%;
  width: 4px;
}

.rc-slider-vertical .rc-slider-dot {
  left: 2px;
  margin-bottom: -4px;
}

.rc-slider-vertical .rc-slider-dot:first-child {
  margin-bottom: -4px;
}

.rc-slider-vertical .rc-slider-dot:last-child {
  margin-bottom: -4px;
}

.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  animation-duration: .3s;
  animation-fill-mode: both;
  display: block !important;
  animation-play-state: paused;
}

.rc-slider-tooltip-zoom-down-leave {
  animation-duration: .3s;
  animation-fill-mode: both;
  display: block !important;
  animation-play-state: paused;
}

.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
.rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
  animation-name: rcSliderTooltipZoomDownIn;
  animation-play-state: running;
}

.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
  animation-name: rcSliderTooltipZoomDownOut;
  animation-play-state: running;
}

.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  transform: scale(0, 0);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.rc-slider-tooltip-zoom-down-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

@keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0);
  }

  100% {
    transform-origin: 50% 100%;
    transform: scale(1, 1);
  }
}

@keyframes rcSliderTooltipZoomDownOut {
  0% {
    transform-origin: 50% 100%;
    transform: scale(1, 1);
  }

  100% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0);
  }
}

.rc-slider-tooltip {
  position: absolute;
  left: -9999px;
  top: -9999px;
  visibility: visible;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider-tooltip * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider-tooltip-hidden {
  display: none;
}

.rc-slider-tooltip-placement-top {
  padding: 4px 0 8px 0;
}

.rc-slider-tooltip-inner {
  padding: 6px 2px;
  min-width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 1;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #6c6c6c;
  border-radius: 6px;
  box-shadow: 0 0 4px #d9d9d9;
}

.rc-slider-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  bottom: 4px;
  left: 50%;
  margin-left: -4px;
  border-width: 4px 4px 0;
  border-top-color: #6c6c6c;
}

/** Progress Bar */

/* Make clicks pass-through */

#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  /* background: #20c997; */
  background: #d5651f;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
}

/* Fancy blur effect */

#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #d5651f, 0 0 5px #d5651f;
  opacity: 1.0;
  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */

#nprogress .spinner {
  /* display: block; */
  display: none;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  border: solid 2px transparent;
  border-top-color: #20c997;
  border-left-color: #20c997;
  border-radius: 50%;
  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}