.droid-module.spider {
  height: 100vh;
  background: radial-gradient(#385B98, #111D2F);
  background: #243E5C;
  color: white;
}

.inner-spider {
  position: relative;
  right: 5%;
  height: 100vh;
  transform: scale(1.2);
  z-index: 10;
}

.spider svg,
.connector svg {
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  pointer-events: none;
}

.connector-icon i[data-fa-i2svg] svg {
  position: unset;
  height: unset;
}

.connector-icon .connector-bolt {
  font-size: 16px;
  color: orange !important;
  left: 5px;
  position: relative;
}

.item {
  overflow: hidden;
  width: 100%;
  color: white;
  display: flex;
  justify-content: center;
  font-size: 3em;
  line-height: 2em;
}

.spider-bubble .bubble .uppercase.bold {
  bottom: 40px;
}

.droid-module .item {
  position: absolute;
}

.item-center {
  top: 45%;
  left: 48.5%;
  transform: translate(-50%, -50%);
  z-index: 12 !important;
}

.spider-bubble {
  width: 100%;
  height: 100%;
}

.item-data {
  left: 30%;
  top: 30%;
}

.item-audience {
  left: 48%;
  top: 20%;
}

.item-journey {
  left: 70%;
  top: 35%;
}

.item-creator {
  left: 70%;
  top: 60%;
  z-index: 12;
}

.item-delivery {
  left: 48%;
  top: 70%;
  z-index: 12;
}

.item-reports {
  left: 30%;
  top: 60%;
}

.spider-lines {
  position: relative;
  left: -80px;
  top: 50px;
}

.spider-line {
  stroke-width: 4px;
  stroke: rgb(0, 0, 0);
}

#droid-spider-center-img {
  right: 0.6%;
  position: relative;
}

#data-line {
  stroke: #077DC5;
}

#audience-line {
  stroke: #9E2799;
}

#journey-line {
  stroke: #04A9A0;
}

#creator-line {
  stroke: #D4DEE9;
}

#delivery-line {
  stroke: #B30943;
}

#reports-line {
  stroke: #349B40;
}

@media (max-width: 767.98px) {
  .inner-spider {
    right: 15%;
    transform: scale(1);
  }
  .item-data {
    left: 25%;
    top: 35%;
  }
  .item-audience {
    left: 50%;
    top: 25%;
  }
  .item-journey {
    left: 75%;
    top: 35%;
  }
  .item-creator {
    left: 80%;
    top: 55%;
  }
  .item-reports {
    left: 20%;
    top: 58%;
  }
}