.info-text-inner {
  background: white;
  padding: 20px;
  border-radius: 3px;
  min-width: 30vw;
}

.info-text-inner button {
  display: block;
  margin: 0px auto;
  background: #243E5C;
  color: white;
  border: none;
  border-radius: 3px;
  padding: 5px;
}

.info-text-inner-header {
  text-align: center;
}

.droid-module.data .data-header .info-text-wrapper {
  top: -1px;
  left: unset;
  position: relative;
  display: inline;
}

.droid-module.data .info-text-wrapper,
.droid-module.reports .info-text-wrapper {
  top: 18px;
  left: 30px;
  position: absolute;
}